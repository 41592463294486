import React from "react";
import { NavLink } from "react-router-dom";

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <footer>
                <div className="container">
                    <div className="row">
                        <div className="col">
                            © 2023 Device Warehouse All rights reserved.
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;