import en from './en';
import th from './th';
import LocalizedStrings from 'react-localization';
let strings = new LocalizedStrings({
    en: en,
    th: th,
});

if (localStorage.getItem('language')) {
    strings.setLanguage(localStorage.getItem('language'));
}
else {
    localStorage.setItem("language", 'th');
    strings.setLanguage('th');
}

/** Force en lang set */
// localStorage.setItem("language", 'en');
// strings.setLanguage('en');

export default strings;