import React from 'react';

const FloatingButtons = () => {
  return (
    <div className="floating-buttons">
        <a
            href="https://line.me/R/ti/p/@490zukar"
            className="floating-button"
            target="_blank"
        >
            <img src="assets/images/lineicon1.png" alt="line"/>
        </a>

        <a
            href="https://www.facebook.com/people/Device-Warehouse/100092512337529/?mibextid=LQQJ4d"
            className="floating-button"
            target="_blank"
        >
            <img src="assets/images/messengericon.png" alt="messenger"/>
        </a>
        <a
            href="tel:+66645195249" 
            rel="noopener noreferrer"
            className="floating-button"
        >
            <img src="assets/images/phone-call.png" alt="line" />
        </a>
    </div>
  );
};

export default FloatingButtons;
