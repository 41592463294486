import React from 'react';
import { Link } from 'react-router-dom'

import Header from "../../components/Layout/Header";
import Footer from "../../components/Layout/Footer";
import WarehouseService from '../../components/Layout/WarehouseService';

import { Helmet } from 'react-helmet';
import strings from '../../components/Language';
class AboutUsScreen extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			lang: localStorage.getItem("language"),
		}
	}

	async componentDidMount() {
		window.scrollTo(0, 0);
	}

	render() {
		return (
			<>
				<Helmet><title>{"Device Warehouse | " + strings["About Us"]}</title></Helmet>
				<Header />

				<div className="container">
					<div className="about">
						<div className="row">
							<div className="col-12 text-center">
								<h2>{strings["About Us"]}</h2>
							</div>
							<div className="col-md-3 text-center">
								<figure><img src="assets/images/logo_lg.png" alt="" /></figure>
							</div>
							<div className="col">
								<p>
									<strong>{strings["Who is Device Warehouse?"]}</strong><br />
								</p>
								<p>
									{strings["Who is Device Warehouse _desc1"]}
								</p>
								<p>
									{strings["Who is Device Warehouse _desc2"]}
								</p>
								<p>
									{strings["Who is Device Warehouse _desc3"]}
								</p>
								<a href="#" className="btn btn-orange"> {strings["Strong customer bonds are forged through genuine connections, exceptional service, and a commitment to always putting their needs first."]}</a>
							</div>
						</div>
					</div>
				</div>
				<section className="value">
					<div className="container">
						<div className="row">
							<div className="col-12 text-center d-sm-block d-md-none">
								<h2>{strings["What are the core values of Device Warehouse?"]}</h2>
							</div>
							<div className="col-md-3 text-center">
								<figure><img src="assets/images/phone_14.png" alt="" /></figure>
							</div>
							<div className="col">
								<h2 className="d-none d-md-block">{strings["What are the core values of Device Warehouse?"]}</h2>
								<div className="row">
									<div className="">
										<p>
											<strong>{strings["Customer-Centricity"]}</strong><br />
											<p>{strings["Customer-Centricity _desc_1"]}</p>
											<p>{strings["Customer-Centricity _desc_2"]}</p>
										</p>
									</div>
									<div className="">
										<p>
											<strong>{strings["Reliaibility"]}</strong><br />
											<p>{strings["Reliaibility _desc_1"]}</p>
											<p>{strings["Reliaibility _desc_2"]}</p>
										</p>
									</div>
									<div className="">
										<p>
											<strong>{strings["efficeiency and safety"]}</strong><br />
											<p>{strings["efficeiency and safety _desc_1"]}</p>
											<p>{strings["efficeiency and safety _desc_2"]}</p>
											<p>{strings["efficeiency and safety _desc_3"]}</p>
										</p>
									</div>
									<div className="">
										<p>
											<strong>{strings["Flexibity"]}</strong><br />
											<p>{strings["Flexibity _desc_1"]}</p>
											<p>{strings["Flexibity _desc_2"]}</p>
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				{/* <div className="container">
					<div className="py-5">
						<div className="row">
							<div className="col-12 text-center d-sm-block d-md-none">
								<h2>{strings["Device warehouse is located at:"]}</h2>
							</div>
							<div className="col-md-3 text-center">
								<figure><img src="assets/images/shop.png" alt="" /></figure>
							</div>
							<div className="col sm-center">
								<h2 className="d-none d-md-block">{strings["Device warehouse is located at:"]}</h2>
								<p>

									{strings["📍 Google Maps Bangkok Branch"]}<br />
									{strings["Office storefront Baan Klang Muang S-Sense Soi Lat Phrao 84"]}<br />
									{strings["click > "]} <a target="_blank" href="https://maps.app.goo.gl/5Yq4HxANk98zKvWf9">https://maps.app.goo.gl/5Yq4HxANk98zKvWf9</a>
									<br />
									<br />
									{strings["📍 Google Maps Bangkok Branch"]}<br />
									{strings["Office storefront Kheha Romklao Branch"]}<br />
									{strings["click > "]} <a href="https://www.google.com/maps/search/?api=1&query=13.7910131,100.5998317">https://www.google.com/maps/search/?api=1&query=13.7910131,100.5998317</a>
									<br />
									<br />
									{strings["📍 Google Maps Chiang Mai Branch"]}<br />
									{strings["In front of the office shop at 5 Santitham Intersection"]}<br />
									{strings["click > "]} <a href="https://www.google.com/maps/search/?api=1&query=18.7920902,99.0239552">https://www.google.com/maps/search/?api=1&query=18.7920902,99.0239552</a>
									<br />
									<br />

								</p>
							</div>
						</div>
					</div>
				</div> */}

				<WarehouseService />
				<Footer />
			</>
		);
	}
}

export default AboutUsScreen;
