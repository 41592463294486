import React from 'react';
import { Link } from 'react-router-dom'

import Header from "../../components/Layout/Header";
import Footer from "../../components/Layout/Footer";
import WarehouseService from '../../components/Layout/WarehouseService';
import { Helmet } from 'react-helmet';
import { api } from '../../components/config/api';
import axios from 'axios';
import { toast } from 'react-toastify';
import strings from '../../components/Language';

class WorkWithUsScreen extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			lang: localStorage.getItem("language"),
			name: '', mobile: '', subject: '', content: '',
			errorMessage: [],
		}
	}

	async componentDidMount() {
		window.scrollTo(0, 0);
	}

	finalSubmit() {
		var self = this;
		self.setState({ errorMessage: [] });
		var json = {
			name: self.state.name,
			mobile: self.state.mobile,
			subject: self.state.subject,
			content: self.state.content,
		}
		axios({
			method: 'POST',
			url: api.contact_us,
			data: json,
			headers: {
				'Authorization': 'Bearer ' + self.state.access_token,
				'Content-Type': 'application/json;multipart/form-data;boundary=SOME_BOUNDARY',
				'Accept-Language': self.state.lang,
			}
		}).then(function (ress) {
			if (ress.data.error == 202 || ress.data.error == '202') {
				if (ress.data.validation && ress.data.validation.length != 0) {
					self.setState({ errorMessage: ress.data.validation });
				} else {
					toast.error(ress.data.message);
				}
			} else {
				toast.success(ress.data.message);
				self.setState({
					name: '', mobile: '', subject: '', content: '',
				})
			}
		}).catch(function (e) {
			console.log(e);
		});
	};

	render() {
		return (
			<>
				<Helmet><title>{"Device Warehouse | " + strings["Work With Us"]}</title></Helmet>
				<Header />

				<section className="value">
					<div className="container">
						<div className="row">
							<div className="col-12 mb-3 text-center">
								<h2>{strings["FAQ"]}</h2>
							</div>
							<div className="col-md-4 text-center">
								<figure><img src="assets/images/wok_wh_us.png" alt="" /></figure>
							</div>
							<div className="col-md-8">
								<div className="accordion" id="accordionExample">
									<div className="accordion-item">
										<h2 className="accordion-header">
											<button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
												{strings["Sales Coordinate (Head Office)"]}
											</button>
										</h2>
										<div id="collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionExample" style={{}}>
											<div className="accordion-body">
												<p>
													{strings["Job details"]}<br />
													<p dangerouslySetInnerHTML={{ __html: strings["Job details desc"] }} />
												</p>
												<p>
													{strings["Qualifications"]}<br />
													<p dangerouslySetInnerHTML={{ __html: strings["Qualifications desc"] }} />
												</p>
												<p>
													{strings["Application details"]}<br />
													<p dangerouslySetInnerHTML={{ __html: strings["Application details desc"] }} />
												</p>
											</div>
										</div>
									</div>
									<div className="accordion-item">
										<h2 className="accordion-header">
											<button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
												{strings["Sales (based at head office / branch)"]}
											</button>
										</h2>
										<div id="collapseTwo" className="accordion-collapse collapse"
											data-bs-parent="#accordionExample">
											<div className="accordion-body">
												<p dangerouslySetInnerHTML={{ __html: strings["Sales (based at head office / branch) desc"] }} />
											</div>
										</div>
									</div>
									<div className="accordion-item">
										<h2 className="accordion-header">
											<button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
												data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
												{strings["Debt Collection Officer (Field)"]}
											</button>
										</h2>
										<div id="collapseThree" className="accordion-collapse collapse"
											data-bs-parent="#accordionExample">
											<div className="accordion-body">
												<p dangerouslySetInnerHTML={{ __html: strings["Debt Collection Officer (Field) desc"] }} />
											</div>
										</div>
									</div>
									<div className="accordion-item">
										<h2 className="accordion-header">
											<button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
												data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
												{strings["Debt collection officer (by phone)"]}
											</button>
										</h2>
										<div id="collapseFour" className="accordion-collapse collapse"
											data-bs-parent="#accordionExample">
											<div className="accordion-body">
												<p dangerouslySetInnerHTML={{ __html: strings["Debt collection officer (by phone) desc"] }} />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				<div className="container">
					<div className="contact">
						<div className="row">
							<div className="col-md-7 mx-auto">
								<div className="mb-3">
									<label className="form-label">{strings["Name"]} - {strings["Surname"]} <span>*</span></label>
									<input placeholder={strings["Name"] + " - " + strings["Surname"]} onChange={(e) => this.setState({ name: e.target.value })} value={this.state.name} name="name" type="text" className="form-control" />
									{this.state.errorMessage.name && <div className='errorMessage'> {this.state.errorMessage.name[0]} </div>}
								</div>
								{/* <div className="mb-3">
										<label className="form-label">Email <span>*</span></label>
										<input placeholder="Email" onChange={(e) => this.setState({ email: e.target.value })} value={this.state.email} name="email" type="text" className="form-control" />
										{this.state.errorMessage.email && <div className='errorMessage'> {this.state.errorMessage.email[0]} </div>}
									</div> */}
								<div className="mb-3">
									<label className="form-label">{strings["Phone Number"]} <span>*</span></label>
									<input placeholder={strings["Telephone Number"]} onChange={(e) => this.setState({ mobile: e.target.value })} value={this.state.mobile} name="mobile" type="text" className="form-control" />
									{this.state.errorMessage.mobile && <div className='errorMessage'> {this.state.errorMessage.mobile[0]} </div>}
								</div>
								<div className="mb-3">
									<label className="form-label">{strings["Contact Matter"]}</label>
									<input placeholder={strings["Contact Matter"]} onChange={(e) => this.setState({ subject: e.target.value })} value={this.state.subject} name="subject" type="text" className="form-control" />
									{this.state.errorMessage.subject && <div className='errorMessage'> {this.state.errorMessage.subject[0]} </div>}
								</div>
								{/* <div className="mb-3">
										<label className="form-label">Position of interest <span>*</span></label>
										<select className="form-select" aria-label="Default select example">
											<option defaultValue={""}>interested position</option>
										</select>
									</div> */}
								<div className="mb-3">
									<label className="form-label">{strings["Additional Text"]}</label>
									<textarea className="form-control" onChange={(e) => this.setState({ content: e.target.value })} value={this.state.content} name="content" rows="3" placeholder={strings["Additional Text"]}></textarea>
									{this.state.errorMessage.content && <div className='errorMessage'> {this.state.errorMessage.content[0]} </div>}
								</div>
								{/* <div className="mb-3">
										<label className="form-label">Attachments (if there is a resume, portfolio)</label>
										<div className="col col-md-6 d-grid">
											<button type="button" className="btn btn-outline-dark btn-atach">Upload<img
												src="assets/images/atch.svg" alt="" /></button>
										</div>
									</div> */}
								<div className="mb-3 text-center">
									<button onClick={() => this.finalSubmit()} type="submit" className="btn btn-orange">{strings["Send Information"]}</button>
								</div>
								<div className="mb-3 text-center">
									<p dangerouslySetInnerHTML={{ __html: strings["work_with_us_thank_you_msg"] }} />
								</div>
							</div>
						</div>
					</div>
				</div>
				<WarehouseService />
				<Footer />
			</>
		);
	}
}

export default WorkWithUsScreen;
