import React from 'react'
import { useLocation } from 'react-router-dom'
import strings from "../Language";
import { Helmet } from 'react-helmet';
import Header from '../Layout/Header';

const NotFound = () => {
  const location = useLocation()

  return (
    <>
      <Helmet><title>{"Device Warehouse | Not Found"}</title></Helmet>
      <Header />
      <div className="form-cover error-middle">
        <h1>404</h1>
        <p>
          {strings.we_could_not_find} <u>{location.pathname}</u> {strings.page}
        </p>
      </div>
    </>
  )
}

export default NotFound
