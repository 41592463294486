import React from "react";
import { NavLink } from "react-router-dom";

import icn_08 from './../../assets/images/icn_08.png';
import icn_09 from './../../assets/images/icn_09.png';
import icn_010 from './../../assets/images/icn_010.png';
import icn_011 from './../../assets/images/icn_011.png';
import strings from "../Language";
class WarehouseService extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <>
                <div className="container">
                    <div className="easy">
                        <div className="row">
                            <div className="col-12">
                                <h2>{strings["“At Device Warehouse, We Offer Nothing Less Than The Best”"]}</h2>
                            </div>
                            <div className="col">
                                <figure>
                                    <img src={icn_08} alt="" />
                                </figure>
                                <h3>{strings["Visit Us Out At Our Branches To Find Out More!"]}</h3>
                            </div>
                            <div className="col">
                                <figure>
                                    <img src={icn_09} alt="" />
                                </figure>
                                <h3>{strings["Flexible & Convenient Payment Methods"]}</h3>
                            </div>
                            <div className="col">
                                <figure>
                                    <img src={icn_010} alt="" />
                                </figure>
                                <h3>{strings["Affordable Installment Plans (Up To 12 Months)"]}</h3>
                            </div>
                            <div className="col">
                                <figure>
                                    <img src={icn_011} alt="" />
                                </figure>
                                <h3>{strings["Genuine & Top-Quality Devices Guaranteed (Comes With A 1 Year Warranty)"]}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default WarehouseService;
