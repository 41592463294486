import React from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom'


import Header from "../../components/Layout/Header";
import Footer from "../../components/Layout/Footer";
import WarehouseService from '../../components/Layout/WarehouseService';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { api } from '../../components/config/api';
import { number_format } from "./../../components/Util";
import Slider from "react-slick";
import {
    Magnifier,
    GlassMagnifier,
    SideBySideMagnifier,
    PictureInPictureMagnifier,
    MOUSE_ACTIVATION,
    TOUCH_ACTIVATION,
    MagnifierContainer,
    MagnifierPreview,
    MagnifierZoom
} from "react-image-magnifiers";
import strings from '../../components/Language';


class ProductScreen extends React.Component {
    constructor(props) {
        super(props);
        const params_id = (this.props && this.props.match && this.props.match.params && this.props.match.params.id) ? this.props.match.params.id : 0;
        this.state = {
            lang: localStorage.getItem("language"),
            params_id: params_id,
            isload: false,
            product_data: [],
            color_code: 0,
            product: [],
            color_list: [],
            capacity_list: [],
            product_gallery: [],
            product_image_main: '',
        }
    }

    async componentDidMount() {
        window.scrollTo(0, 0)

        this.getData();
    }

    getData() {
        var self = this;
        axios({
            method: 'GET',
            url: api.product + "/" + this.state.params_id,
            headers: {
                'Authorization': 'Bearer ' + self.state.access_token,
                'Content-Type': 'application/json',
                'Accept-Language': self.state.lang,
            }
        }).then(function (ress) {

            if (ress.data && ress.data.error === 200) {
                self.setState({
                    isload: true,
                    product_data: (ress.data && ress.data.data) ? ress.data.data : [],
                    product: (ress.data && ress.data.data && ress.data.data.product) ? ress.data.data.product : [],
                    product_gallery: (ress.data && ress.data.data && ress.data.data.product && ress.data.data.product.gallery) ? ress.data.data.product.gallery : [],
                    product_image_main: (ress.data && ress.data.data && ress.data.data.product && ress.data.data.product.image_url) ? ress.data.data.product.image_url : '',
                    color_list: (ress.data && ress.data.data && ress.data.data.color_list) ? ress.data.data.color_list : [],
                    capacity_list: (ress.data && ress.data.data && ress.data.data.capacity_list) ? ress.data.data.capacity_list : []
                });
            }
        }).catch(function (e) {
            console.log(e);
        });
    };

    render() {
        var window_innerHeight = window.innerWidth;
        var slidesToShow = (this.state.product_gallery.length < 3) ? this.state.product_gallery.length : 3;
        if (window_innerHeight < 431) {
            slidesToShow = 1;
        } else if (window_innerHeight < 769) {
            slidesToShow = (this.state.product_gallery.length < 2) ? this.state.product_gallery.length : 2;
        }
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: slidesToShow,
            slidesToScroll: 1
        };
        return (
            <>
                <Helmet><title>{"Device Warehouse | " + strings["Installment"]}</title></Helmet>
                <Header />

                <div className="container">
                    <div className="product">
                        <div className="row">
                            <div className="col-12 text-center position-relative">
                                <a onClick={() => this.props.history.goBack()} className="btn btn-back px-4 d-none d-md-block">
                                    <i className="fa fa-angle-left me-2"></i> {strings["Back"]}
                                </a>
                                <NavLink to={"/installment-list"} className="float-start d-sm-block d-md-none">
                                    <img src="assets/images/arw_left_01.svg" alt="" />
                                </NavLink>
                                <h2>{(this.state.product && this.state.product.en_name) ? this.state.product.en_name : ''}</h2>
                            </div>
                            <div className="col-md-9 mx-auto">
                                <div className="row">
                                    <div className="col text-center">
                                        <div className="gallery">
                                            <div className="">
                                                {/* <img src={(this.state.product_image_main) ? this.state.product_image_main : ''} alt="" /> */}
                                                {this.state.isload ?
                                                    <SideBySideMagnifier imageSrc={(this.state.product_image_main) ? this.state.product_image_main : ''} />
                                                    : null}
                                            </div>
                                            <div className="thumb">
                                                <div className="row">
                                                    <Slider {...settings}>
                                                        {this.state.product_gallery.map((item, i) =>
                                                            <div style={{ marginRight: 10 }}>
                                                                <a style={{ cursor: "pointer" }} onClick={() => this.setState({ product_image_main: item.src_link })}><img src={item.src_link} alt="" style={{ width: 75, height: 75 }} /></a>
                                                            </div>
                                                        )}
                                                    </Slider>
                                                </div>
                                            </div>
                                            <h1 style={{ marginTop: 75 }}>{(this.state.product && this.state.product.en_name) ? this.state.product.en_name : ''}</h1>
                                            {/* <h3>128 GB / 256 GB</h3> */}
                                        </div>
                                    </div>
                                    <div className="col-md-7 col-lg-6">
                                        <h2 className="sub">{(this.state.product && this.state.product.en_name) ? this.state.product.en_name : ''}</h2>
                                        <h3><span>฿{number_format((this.state.product && this.state.product.minimum_price) ? this.state.product.minimum_price : 0, 2, ".", ",")} – ฿{number_format((this.state.product && this.state.product.maximum_price) ? this.state.product.maximum_price : 0, 2, ".", ",")}</span></h3>
                                        <div className="row my-3 align-items-center">
                                            <div className="col-3">
                                                <h2>{strings["Color"]}</h2>
                                            </div>
                                            <div className="col-9 shades">
                                                <div className="color_view">
                                                    {this.state.color_list.map((item, i) =>
                                                        <div onClick={() => this.setState({ color_code: item.id })} className='color_code' style={{ backgroundColor: item.color_code, borderWidth: (this.state.color_code == item.id) ? 2 : 0, borderStyle: "solid", borderColor: "#000" }}></div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row gx-2 my-3 align-items-center">
                                            <div className="col-3">
                                                <h2>{strings["Capacity"]}</h2>
                                            </div>
                                            <div className="col-9 capacity_list">
                                                {this.state.capacity_list.map((item, i) =>
                                                    <div className="col-3 d-grid">
                                                        <button type="button" onClick={() => this.setState({ capacity: item.id })} className="btn btn-outline-dark" style={{ backgroundColor: this.state.capacity == item.id ? "#000" : "#FFF", color: this.state.capacity == item.id ? "#FFF" : "#000" }}>{item.name}</button>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        {/* <div className="row gx-2 my-3 align-items-center">
                                            <div className="col-3">
                                                <h2>down payment</h2>
                                            </div>
                                            <div className="col-8">
                                                <select className="form-select">
                                                    <option defaultValue={""}>choose</option>
                                                </select>
                                            </div>
                                        </div> */}
                                        <div className="row gx-2 my-3 align-items-center">
                                            <div className="col-3">
                                                <h2>{strings["Quantity"]}</h2>
                                            </div>
                                            <div className="col-3"><input className="form-control" type="text" defaultValue="1" /></div>
                                        </div>
                                        <div className="mb-3">
                                            {this.state.params_id ?
                                                <Link to={`/product-register/${this.state.params_id}`} type="submit" className="btn btn-primary">{strings["Register"]}</Link>
                                                :
                                                <Link to={`/product-register`} type="submit" className="btn btn-primary">{strings["Register"]}</Link>
                                            }
                                        </div>
                                        <p>
                                            SKU: {(this.state.product && this.state.product.id_word) ? this.state.product.id_word : 'N/A'} <br />
                                            {/* Categories: iPhone, smartphone <br /> */}
                                            {/* Tags: iphone 13 pro max, iPhone installments */}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="seller text-start">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-9 mx-auto">
                                <p dangerouslySetInnerHTML={{ __html: (this.state.product && this.state.product.en_detail) ? this.state.product.en_detail : '' }} />
                            </div>
                        </div>
                    </div>
                </section>

                {(this.state.product && this.state.product.single_image_url) ?
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <img src={(this.state.product && this.state.product.single_image_url) ? this.state.product.single_image_url : ''} alt="" />
                            </div>
                        </div>
                    </div>
                    : null}

                <WarehouseService />
                <Footer />
            </>
        );
    }
}

export default ProductScreen;