const th = {
    "confirm": "ยืนยัน",
    "coming_soon": "เร็วๆ นี้",
    "processing": "ส่งธุรกรรมแล้ว โปรดรอ",
    "error": "ข้อผิดพลาด",
    "success": "ความสำเร็จ",
    "close": "ปิด",
    "language": "ภาษา",
    "language_changed": "เปลี่ยนภาษาแล้ว",
    "install_metamask": "ติดตั้ง Metamask",
    "please_connect": "กรุณาเชื่อมต่อ Wallet",
    "signin": "เข้าสู่ระบบ",
    "signin_to_continue": "ลงชื่อเข้าใช้เพื่อดำเนินการต่อ",
    "register_success": "ลงทะเบียนสำเร็จ",
    "loading": "กำลังประมวลผล...",
    "nft_collect": "เก็บรวบรวม",
    "nft_collected": "รวบรวม",
    "view": "ดู",
    "amount": "จำนวน",
    "date": "วันที่",
    "description": "คำอธิบาย",
    "next": "ต่อไป",
    "previous": "ก่อนหน้า",
    "approved": "ที่ได้รับการอนุมัติ",
    "approve": "อนุมัติ",
    "unknown_net": "เน็ตที่ไม่รู้จัก",
    "ongoing": "กำลังดำเนินการอยู่",
    "expired": "หมดอายุแล้ว",
    "collected": "รวบรวม",
    "download": "ดาวน์โหลด",
    "collect": "เก็บรวบรวม",
    "sign_in": "เข้าสู่ระบบ",
    "wait_one_minute_for_retry": "รอ 1 นาทีก่อนลองอีกครั้ง",
    "Installment": "ค่างวดเงินผ่อน",
    "iPhone Money Exchange": "แลกเงินไอโฟน",
    "About Us": "เกี่ยวกับเรา",
    "FAQ": "คำถามที่พบบ่อย",
    "Article": "บทความ",
    "Contact Us": "ติดต่อเรา",
    "Work With Us": "ทำงานกับเรา",
    "Thai": "แบบไทย",
    "English": "English",
    "Focus on safety and confidence by choosing Device Warehouse service.": "เน้นความปลอดภัยและความมั่นใจโดยเลือกใช้บริการ Device Warehouse",
    "There is a clear storefront.": "มีหน้าร้านชัดเจน.",
    "Open for service more than 10 branches": "เปิดให้บริการมากกว่า 10 สาขา",
    "Supported by applications, convenient": "รองรับแอพพลิเคชั่นได้สะดวก",
    "easy, safe": "ง่ายปลอดภัย",
    "Installment 0%": "ผ่อน 0%",
    "up to 12 months": "นานถึง 12 เดือน",
    "Genuine machine, 1st hand": "เครื่องแท้มือ1",
    "guaranteed by Thai center": "การันตีโดยศูนย์ไทย",
    "Home": "บ้าน",
    "Apply now": "ลงทะเบียนเลย",
    "What are your customers interested in?": "ลูกค้าของคุณสนใจอะไร?",
    "Which machine are you aiming for? Check it out!": "คุณมุ่งเป้าไปที่เครื่องไหน? ตรวจสอบออก!",
    "Easy installment without card": "ผ่อนสบายไม่มีบัตร",
    "Low down payment, comfortable installments": "ดาวน์ต่ำผ่อนสบายๆ",
    "Click now!": "คลิกเลย!",
    "Hot money iPhone in your hand can help!": "iPhone เงินร้อนในมือคุณช่วยได้!",
    "iphone money exchange": "แลกเงินไอโฟน",
    "Earn good money. Have an iPhone.": "หารายได้ดี. มีไอโฟน.",
    "Best seller right now!": "ขายดีที่สุดในขณะนี้!",
    "Prev": "ก่อนหน้า",
    "Next": "ต่อไป",
    "Hot": "Hot",
    "Choose a pattern": "เลือกรูปแบบ",
    "Convenience, easy installment with Device Warehouse": "สะดวกสบาย ผ่อนง่าย ด้วย Device Warehouse",
    "iPhone installment": "ค่างวดไอโฟน",
    "without credit card": "โดยไม่ต้องใช้บัตรเครดิต",
    "Easy installments, quick results": "ผ่อนสบาย รู้ผลเร็ว",
    "no need to have a guarantor": "ไม่จำเป็นต้องมีผู้ค้ำประกัน",
    "put down payment immediately": "วางเงินดาวน์ทันที",
    "get the device to use": "รับอุปกรณ์ไปใช้",
    "Any occupation": "อาชีพใดก็ได้",
    "can pay off.": "สามารถชำระได้",
    "Convenience, easy installment with Device Warehouse": "สะดวกสบาย ผ่อนง่าย ด้วย Device Warehouse",
    "Original equipment, 1st hand, guaranteed by Thai center": "อุปกรณ์แท้มือ1รับประกันศูนย์ไทย",
    "Installment 0% up to 12 months": "ผ่อน 0% สูงสุด 12 เดือน",
    "Supported by applications, convenient, easy, safe": "รองรับด้วยแอพพลิเคชั่น สะดวก ง่าย ปลอดภัย",
    "There is a clear storefront Open for service more than 4 branches": "มีหน้าร้านชัดเจน เปิดให้บริการมากกว่า 4 สาขา",
    "Who is Device Warehouse?": "คลังอุปกรณ์คือใคร?",
    "Who is Device Warehouse _desc1": "Device Warehouse คือร้านผ่อนสบายๆ สำหรับ iPhone, iPad โดยใช้บัตรประชาชนเพียงใบเดียว เราเปิดมาแล้วกว่า 10 สาขา และจะขยายอย่างต่อเนื่องเพื่อให้ลูกค้าได้เข้าถึง iPhone iPad ในราคาที่จับต้องได้",
    "Who is Device Warehouse _desc2": "เรามุ่งเน้นการให้บริการลูกค้าด้วยความจริงใจ รวดเร็ว และปลอดภัย เราชำระเงินผ่านแอปพลิเคชัน ลูกค้าก็สบายใจได้ ไม่ว่าลูกค้าอยากได้ iPhone รุ่นไหนก็ตาม ติดขัดหรืออยากให้เราช่วยอะไร เรายินดีให้คำปรึกษาและแนะนำรุ่นที่เหมาะสมให้เกิดประโยชน์สูงสุดเหมือนเพื่อนๆที่ช่วยเหลือกัน",
    "Friends who help facilitate and give each other a good quality of life.": "เพื่อนที่คอยอำนวยความสะดวกและมอบคุณภาพชีวิตที่ดีให้แก่กัน",
    "That Device Warehouse stands for": "คลังอุปกรณ์นั้นย่อมาจาก",
    "companionship": "มิตรภาพ",
    "companionship_desc": "เราให้บริการทุกคนเหมือนเป็นเพื่อน เพื่อนคือคนที่พูดคุยกันในชีวิตประจำวัน มีอะไรก็แบ่งปันได้ไม่ว่าจะมีปัญหาอะไรก็ตาม เพื่อนจะคอยอยู่เคียงข้างและช่วยเหลือซึ่งกันและกันเสมอ",
    "credibility": "ความน่าเชื่อถือ",
    "credibility_desc": "เรามีหน้าร้านที่ชัดเจน เปิดให้บริการ มากกว่า 10 สาขาใช้เทคโนโลยีเข้ามาช่วยทำงานร่วมกัน มีแอปพลิเคชันของตัวเองอย่างเป็นระบบ สนับสนุนทุกขั้นตอนที่เราตั้งใจเพื่อให้คุณได้รับ ความปลอดภัยสูงสุด",
    "Tangible": "จับต้องได้",
    "Tangible_desc": "เราต้องการให้ทุกคนมีโอกาสได้เข้าถึงผลิตภัณฑ์ที่มีคุณภาพและบริการที่ดีในราคาที่เอื้อมถึง สิ่งดีๆที่จับต้องได้เหมาะสำหรับทุกคน",
    "Fast": "เร็ว",
    "Fast_desc": "เราไม่ต้องการให้ลูกค้ารอนาน มันทำให้ใจเราอ่อนแอเราจึงเน้นการบริการที่รวดเร็ว ปลอดภัย และต้องทันท่วงทีด้วย",
    "Device Warehouse has opened more than 11 branches.": "คลังอุปกรณ์เปิดแล้วมากกว่า 11 สาขา",
    "aboutus_dw_desc1": "สาขาวงเวียนใหญ่ ใกล้ BTS วงเวียนใหญ่",
    "aboutus_dw_desc2": "สาขาฟิวเจอร์พาร์ครังสิต ชั้น 3 (โซนไอที) หน้าร้านเดียวกับ digital2home",
    "aboutus_dw_desc3": "สาขาบางกะปิ หน้าโครงการตะวันนา ติดถนนใหญ่ (ก่อนถึงทางเข้าแม็คโคร)",
    "aboutus_dw_desc4": "สาขาชลบุรี ถนนลงหาดบางแสน (ข้างร้าน Option Bangsaen วัยรุ่น) ขอนแก่น",
    "aboutus_dw_desc5": "สาขาถนนกลางเมือง (ฝั่งร้านทอง ตรงข้ามตลาดโต้รุ่ง)",
    "aboutus_dw_desc6": "สาขาอุดรธานี ถนนโพศรี (ข้างมูลนิธิ เยื้องร้านข้าวต้มเพิ่มพูน)",
    "aboutus_dw_desc7": "สาขาพิษณุโลก ถนนสีหราชเดโชชัย (ข้างเบเกอรี่ทาวน์)",
    "aboutus_dw_desc8": "สาขาเชียงใหม่ เซ็นทรัลเชียงใหม่แอร์พอร์ต ห้อง 316 ชั้น 3 (ใกล้ศูนย์ดีแทค)",
    "aboutus_dw_desc9": "Apple house สาขาลาดพร้าว Central Ladprao ชั้น 3 (หน้าเจมาร์ท)",
    "aboutus_dw_desc10": "Apple house สาขา Westgate เซ็นทรัลเวสต์เกต ชั้น 2 (ใกล้ทางเข้าอิเกีย)",
    "aboutus_dw_desc11": "แอปเปิ้ลเฮ้าส์ สาขาจันทบุรี เซ็นทรัลจันทบุรี ชั้น 1",
    "Easy installment iphone Use a single ID card.": "ผ่อนสบายๆ iphone ใช้บัตรประชาชนใบเดียว",
    "Credit card can pay installments.": "บัตรเครดิตสามารถผ่อนชำระได้",
    "Store address": "ที่อยู่ร้าน",
    "store_address_": "99, 99 ถนน xxxx แขวง xxxx <br /> เขต xxxx กรุงเทพมหานคร 10600<br /> Tel.099-9999999",
    "Contact channel": "ช่องทางการติดต่อ",
    "FanPage": "แฟนเพจ",
    "Line OA": "สายโอเอ",
    "faq_que_1": "อาชีพไหนผ่อนได้?",
    "faq_ans_1_1": "ร้านคลังอุปกรณ์เสนอทางเลือกให้ลูกค้าทุกคนสามารถชำระค่าสินค้าเป็นงวดได้ โดยไม่คำนึงถึงอาชีพของพวกเขา วิธีการชำระเงินที่สะดวกสบายนี้ใช้ได้กับนักเรียน ผู้ขาย พนักงานบริษัท คนทำงานอิสระ พนักงานขับรถ คนขับแท็กซี่ แม่บ้าน พยาบาล และอื่นๆ อีกมากมาย ตราบใดที่พวกเขามีอายุ 18 ปีขึ้นไป ลูกค้าสามารถชำระค่าโทรศัพท์เป็นงวดๆ ได้ง่ายๆ โดยไม่ต้องใช้บัตรเครดิต อย่างไรก็ตาม ลูกค้าจำเป็นต้องจัดเตรียมหลักฐานเบื้องต้นที่จำเป็น เช่น สลิปเงินเดือน หรือ Statement เพื่อประกอบการพิจารณา กระบวนการนี้ทำให้พวกเขาได้รับสิทธิประโยชน์ในการชำระค่าโทรศัพท์มือถือแบบผ่อนชำระโดยไม่ต้องพึ่งบัตรเครดิต",
    "faq_que_2": "ขั้นตอนการลงทะเบียนเพื่อพิจารณา",
    "faq_ans_2_1": "1. เริ่มต้นด้วยการลงทะเบียนบนเว็บไซต์คลังอุปกรณ์ ค้นหาและคลิกที่ปุ่ม `ลงทะเบียน` เพื่อเริ่มกระบวนการ",
    "faq_ans_2_2": "2. เลือกผลิตภัณฑ์ที่คุณต้องการซื้อ กรณีสนใจรีไฟแนนซ์ (แลกมือถือเป็นเงิน) สามารถข้ามไปขั้นตอนที่ 3 ได้เลย",
    "faq_ans_2_3": "3. กรอกข้อมูลส่วนตัวเพิ่มเติมตามที่ร้องขอ ตรวจสอบให้แน่ใจว่าคุณแนบเอกสารที่จำเป็นทั้งหมด",
    "faq_ans_2_4": "4. เมื่อคุณกรอกข้อมูลเรียบร้อยแล้ว ให้ส่งข้อความถึงผู้ดูแลระบบเพื่อแจ้งให้ทราบถึงความคืบหน้าของคุณ",
    "faq_ans_2_5": "5. รอแอดมินติดต่อกลับพร้อมแจ้งผลการพิจารณาและวันนัดหมายลงนามในสัญญา",
    "faq_que_3": "ร้านค้าจะหลอกลวงและขายโทรศัพท์หรือไม่?",
    "faq_ans_3_1": "ร้านคลังอุปกรณ์เป็นบริษัทที่มุ่งจัดหาทางเลือกอื่นให้กับลูกค้าที่ต้องการซื้อโทรศัพท์มือถือ ไอโฟน และไอแพด แต่ไม่มีบัตรเครดิต เราเข้าใจดีว่าไม่ใช่ทุกคนสามารถซื้ออุปกรณ์เหล่านี้ได้ทันที ดังนั้นทางร้านเราจึงขอเสนอโซลูชั่นที่ลูกค้าสามารถชำระเงินดาวน์และรับเครื่องได้ทันที จากนั้นพวกเขาสามารถผ่อนชำระขณะใช้งานได้",
    "faq_ans_3_2": "สิ่งสำคัญคือลูกค้าจะต้องวางแผนและจัดการการผ่อนชำระ iPhone อย่างรอบคอบในลักษณะที่ไม่เกินความสามารถทางการเงินของลูกค้า เราให้ความสำคัญกับความโปร่งใสและให้ความมั่นใจกับลูกค้าของเราว่าร้านคลังอุปกรณ์จะไม่หลอกลวงพวกเขาในทางใดทางหนึ่ง เป้าหมายของเราคือการมอบทางเลือกที่น่าเชื่อถือและไว้วางใจได้ให้กับลูกค้าในการซื้ออุปกรณ์ที่ต้องการโดยไม่ต้องใช้บัตรเครดิต",
    "faq_que_4": "ฉันสามารถทำสัญญาออนไลน์ได้หรือไม่?",
    "faq_ans_4_1": "ใช่ คลังอุปกรณ์เสนอการจองโทรศัพท์ทางออนไลน์เพื่ออำนวยความสะดวกให้กับลูกค้า พวกเขาสามารถเรียกดูอุปกรณ์ที่มีอยู่บนเว็บไซต์ได้อย่างง่ายดายและเลือกอุปกรณ์ที่เหมาะกับความต้องการของพวกเขา เมื่อการจองได้รับการอนุมัติ ลูกค้าจะสามารถเลือกรับอุปกรณ์ได้ 2 วิธี",
    "faq_ans_4_2": "ตัวเลือกแรกคือไปที่ร้านคลังอุปกรณ์และรับโทรศัพท์เป็นการส่วนตัว ช่วยให้ลูกค้าสามารถตรวจสอบสภาพของอุปกรณ์ กรอกเอกสารที่จำเป็น แล้วนำกลับบ้านด้วย",
    "faq_ans_4_3": "หรืออีกทางหนึ่ง ลูกค้าสามารถเลือกจองพนักงานขับรถที่จะจัดส่งโทรศัพท์ไปที่บ้านของตนได้โดยตรง ทำให้ลูกค้าไม่ต้องมาที่ร้านด้วยบริการจัดส่งที่สะดวกและไม่ยุ่งยาก",
    "faq_ans_4_4": "ด้วยการจัดเตรียมสองตัวเลือกนี้ Device warehouse มีเป้าหมายเพื่อให้ลูกค้ามีความยืดหยุ่นตามความต้องการและความสะดวกสบายของพวกเขา บริษัทมุ่งมั่นที่จะสร้างประสบการณ์ที่ราบรื่นและน่าพึงพอใจให้กับลูกค้าทุกคน",
    "faq_que_5": "ฉันสามารถผ่อนชำระอะไรได้บ้าง?",
    "faq_ans_5_1": "ที่ Device Warehouse เราเสนอทางเลือกให้ลูกค้าในการซื้อ iPhone และ iPad แบบผ่อนชำระ ลูกค้าสามารถเลือกชำระเป็นเงินสดหรือผ่อนชำระกับร้านเราได้โดยตรง เมื่อเลือกผ่อนชำระลูกค้าสามารถชำระเงินดาวน์เพื่อรับและเริ่มใช้เครื่องได้ทันทีโดยผ่อนชำระส่วนที่เหลือต่อไป",
    "faq_ans_5_2": "เราเข้าใจถึงความสำคัญของ iPhone และ iPad สำหรับนักเรียน ดังนั้นเราจึงเสนอแผนการผ่อนชำระพิเศษสำหรับลูกค้านักศึกษา นักเรียนสามารถใช้ประโยชน์จากระยะเวลาการผ่อนชำระที่นานขึ้นได้ถึง 12 เดือน เพื่อให้สามารถจัดการทางการเงินได้มากขึ้น นอกจากนี้ นักศึกษายังสามารถแบ่งปันเงินค่าขนมเพื่อสมทบทุนผ่อนชำระได้ ทำให้สะดวกและไม่ยุ่งยาก",
    "faq_que_6": "การแลกเปลี่ยนเงินผ่านมือถือคืออะไร?",
    "faq_ans_6_1": "Mobile Money Exchange เป็นบริการที่จัดทำโดย Device warehouse เพื่อช่วยเหลือลูกค้าในการทำสัญญากับร้านค้าสำหรับ iPhone หรือ iPad ของพวกเขา โดยบริการนี้ลูกค้าจะได้รับทั้งเงินและ iPhone หรือ iPad ทำให้สามารถใช้เครื่องได้ตามปกติ บริการนี้แตกต่างจากการรับจำนำโทรศัพท์มือถือแบบเดิมๆ โดยที่ลูกค้าจะไม่รับเครื่องคืนจนกว่าจะชำระเงินเต็มจำนวน",
    "faq_ans_6_2": "คลังอุปกรณ์ช่วยให้แน่ใจว่าลูกค้าสามารถเก็บและใช้อุปกรณ์ดั้งเดิมของตนได้ตลอดระยะเวลาสัญญา นี่เป็นทางเลือกที่ยืดหยุ่นและสะดวกกว่าเมื่อเปรียบเทียบกับบริการรับจำนำแบบดั้งเดิม เมื่อใช้ Mobile Money Exchange ลูกค้าจะได้รับความช่วยเหลือทางการเงินที่ต้องการในขณะที่ยังคงสามารถเข้าถึง iPhone หรือ iPad ของตนได้",
    "faq_ans_6_3": "คลังอุปกรณ์มุ่งมั่นที่จะนำเสนอโซลูชั่นที่เป็นนวัตกรรมที่ตรงตามความต้องการของลูกค้า",
    "faq_que_7": "ฉันสามารถดาวน์โหลดแอพด้วย icloud ได้หรือไม่",
    "faq_ans_7_1": "คลังอุปกรณ์จะล็อคบัญชี iCloud ลูกค้าสามารถเข้าสู่ระบบ App Store ของตนเองได้อย่างมั่นใจโดยใช้ Apple ID ส่วนตัวและดาวน์โหลดแอพได้ตามปกติ เราเคารพและให้ความสำคัญกับความเป็นส่วนตัวของลูกค้าตลอดกระบวนการทั้งหมด",
    "faq_ans_7_2": "หากคุณมีคำถามหรือข้อกังวลเพิ่มเติม โปรดแจ้งให้เราทราบ เราอยู่ที่นี่เพื่อช่วยเหลือคุณ",
    "faq_que_8": "เครื่องไทยกับเครื่องต่างประเทศต่างกันอย่างไร?",
    "faq_ans_8_1": "โดยทั่วไปแล้วไม่มีความแตกต่างอย่างมีนัยสำคัญระหว่าง iPhone ต่างประเทศและ iPhone ของไทย โดยทั่วไปแล้ว iPhone ระหว่างประเทศจะมีการรองรับภาษาที่กว้างกว่าและความเข้ากันได้กับคลื่นความถี่เซลลูลาร์ที่หลากหลายกว่า",
    "faq_ans_8_2": "นอกจากนี้ การรับประกัน iPhone โดยทั่วไปจะเป็นสากล ซึ่งหมายความว่าความคุ้มครองการรับประกันควรจะเหมือนกันไม่ว่าอุปกรณ์จะซื้อในประเทศไทยหรือต่างประเทศก็ตาม",
    "faq_que_9": "เวลาทำการคือเท่าไร?",
    "faq_ans_9_1": "เพื่อหลีกเลี่ยงการรอคิวนานเมื่อซื้อ iPhone แบบผ่อนชำระจากร้านค้าของเรา ลูกค้ามีตัวเลือกในการนัดหมายกับผู้ดูแลระบบผ่านบริการแชทของเรา การทำเช่นนี้จะช่วยให้มั่นใจได้ว่าพวกเขาจะได้รับความสนใจอย่างรวดเร็วและความช่วยเหลือเฉพาะบุคคล การนัดหมายของเราให้บริการตั้งแต่วันจันทร์ถึงวันอาทิตย์ เวลา 9.00 น. ถึง 21.00 น.",
    "Sales Coordinate (Head Office)": "ประสานงานการขาย (สำนักงานใหญ่)",
    "Job details": "รายละเอียดงาน",
    "Job details desc": "- ดูแลลูกค้าที่ติดต่อ Ufriend ทาง Line, Facebook, Tiktok <br /> - ปิดการขายและป้อนข้อมูลเข้าระบบของ Ufriend เพื่อส่งต่อให้กับพนักงาน <br /> - งานขายสาขาตอบคำถามและให้ข้อมูลเกี่ยวกับเงื่อนไขการสั่งซื้อซื้อสินค้า และบริการต่างๆ เพื่อช่วยให้ลูกค้าตัดสินใจผ่านช่องทางออนไลน์และออฟไลน์ (ทางโทรศัพท์) [แชท 80% โทร 20% โดยประมาณ] <br /> - ใช้ Excel เพื่อดูข้อมูลหุ้นและดำเนินการอื่นๆ ที่เกี่ยวข้อง <br /> - ประสานงานกับพนักงานคนอื่นๆ. เพื่อค้นหาข้อมูลเพื่อตอบลูกค้าได้อย่างถูกต้อง <br /> - สภาพการทำงาน 6 วัน (วันหยุดตามข้อตกลงของบริษัท) เน้นความรับผิดชอบ ชอบทำงานของตัวเองให้สำเร็จ",
    "Qualifications": "คุณสมบัติ",
    "Qualifications desc": "- หญิง/ชาย อายุ 22 - 35 ปี <br />- คุณสมบัติ: ประกาศนียบัตรวิชาชีพชั้นสูง, ประกาศนียบัตรวิชาชีพชั้นสูง หรือ ปริญญาตรี ในสาขาที่เกี่ยวข้อง <br />- สนใจผลิตภัณฑ์ของ Apple เช่น iPhone/iPad <br /> - มีใจรักในการเรียนกับผลิตภัณฑ์มือถือ มีความรู้พื้นฐานเกี่ยวกับโทรศัพท์ <br />- รักการบริการและดูแลลูกค้า <br />- มีทักษะในการใช้ภาษาไทยได้อย่างถูกต้อง (โดยเฉพาะการพิมพ์) <br />- มีความสามารถในการใช้งาน คอมพิวเตอร์ได้อย่างคล่องแคล่วและทำงานอยู่หน้าคอมพิวเตอร์ตลอดเวลา ทำงาน 8 ชั่วโมง <br />- สามารถให้ข้อมูลหรือปิดการขายทางโทรศัพท์ได้<br />- สามารถทำงานร่วมกับผู้อื่นในการประสานงานได้เป็นอย่างดี มีมนุษยสัมพันธ์ดี <br />- สามารถใช้ Microsoft Excel/Email ในระดับพื้นฐานได้ <br />- />- สามารถทำงานได้ 6 วันต่อสัปดาห์ หยุดเสาร์-อาทิตย์ไม่ได้.",
    "Application details": "รายละเอียดการสมัคร",
    "Application details desc": "สนใจสมัครติดต่อ hr@devicewarehouse.co.th โดยแนบข้อมูลดังนี้ <br /> ประวัติการศึกษา <br /> ประวัติการทำงาน <br />แนะนำตัวเองสั้นๆ <br />อีเมลเรื่อง `สมัครตำแหน่ง Sales Coordinate ` <br />สอบถามข้อมูลเพิ่มเติมได้ที่ <br />โทร: 099-999-9999 (ฝ่ายทรัพยากรบุคคล) เวลา 10.00 น. ถึง 17.30 น.",
    "Sales (based at head office / branch)": "ยอดขาย (ขึ้นอยู่กับสำนักงานใหญ่/สาขา)",
    "Sales (based at head office / branch) desc": "มันถูกซ่อนไว้โดยค่าเริ่มต้น จนกว่าปลั๊กอินยุบจะเพิ่มคลาสที่เหมาะสมที่เราใช้เพื่อจัดสไตล์แต่ละองค์ประกอบ คลาสเหล่านี้ควบคุมลักษณะที่ปรากฏโดยรวม เช่นเดียวกับการแสดงและการซ่อนผ่านการเปลี่ยน CSS คุณสามารถแก้ไขสิ่งเหล่านี้ได้ด้วย CSS ที่กำหนดเองหรือแทนที่ตัวแปรเริ่มต้นของเรา",
    "Debt Collection Officer (Field)": "เจ้าหน้าที่ทวงถามหนี้ (ภาคสนาม)",
    "Debt Collection Officer (Field) desc": "มันถูกซ่อนไว้โดยค่าเริ่มต้น จนกว่าปลั๊กอินยุบจะเพิ่มคลาสที่เหมาะสมที่เราใช้เพื่อจัดสไตล์แต่ละองค์ประกอบ คลาสเหล่านี้ควบคุมลักษณะที่ปรากฏโดยรวม เช่นเดียวกับการแสดงและการซ่อนผ่านการเปลี่ยน CSS คุณสามารถแก้ไขสิ่งเหล่านี้ได้ด้วย CSS ที่กำหนดเองหรือแทนที่ตัวแปรเริ่มต้นของเรา",
    "Debt collection officer (by phone)": "เจ้าหน้าที่ทวงถามหนี้ (ทางโทรศัพท์)",
    "Debt collection officer (by phone) desc": "มันถูกซ่อนไว้โดยค่าเริ่มต้น จนกว่าปลั๊กอินยุบจะเพิ่มคลาสที่เหมาะสมที่เราใช้เพื่อจัดสไตล์แต่ละองค์ประกอบ คลาสเหล่านี้ควบคุมลักษณะที่ปรากฏโดยรวม เช่นเดียวกับการแสดงและการซ่อนผ่านการเปลี่ยน CSS คุณสามารถแก้ไขสิ่งเหล่านี้ได้ด้วย CSS ที่กำหนดเองหรือแทนที่ตัวแปรเริ่มต้นของเรา",
    "Name": "ชื่อ",
    "Surname": "นามสกุล",
    "Phone Number": "หมายเลขโทรศัพท์",
    "Telephone Number": "หมายเลขโทรศัพท์",
    "Contact Matter": "ติดต่อเรื่อง",
    "Additional Text": "ข้อความเพิ่มเติม",
    "Send Information": "ส่งข้อมูล",
    "work_with_us_thank_you_msg": "ขอบคุณทุกท่านที่สนใจร่วมงานกับเรา <br /> ยินดีต้อนรับทุกท่านเข้าสู่ส่วนหนึ่งของ Device Warehouse",
    "- Any occupation can pay by installment.": "-อาชีพไหนก็ผ่อนได้",
    "- Easy installments, quick results, no need to have a guarantor": "- ผ่อนสบาย รู้ผลเร็ว ไม่ต้องมีคนค้ำ",
    "- Place a down payment right away get the device to use": "- วางเงินดาวน์ทันทีเพื่อรับเครื่องไปใช้",
    "- Genuine machine, 1st hand, Thai center warranty&lt;": "- เครื่องแท้ มือ 1 ประกันศูนย์ไทย&lt;",
    "- Guaranteed grade A machine with 1 year in house warranty": "- รับประกันเครื่องเกรด A พร้อมรับประกันในบ้าน 1 ปี",
    "- iPhone, easy installment, use only one ID card": "- ไอโฟน ผ่อนสบาย ใช้แค่บัตรประชาชนใบเดียว",
    "- Book a appointment and send the phone to your home": "- จองนัดหมายและส่งโทรศัพท์ถึงบ้านคุณ",
    "3 easy steps to use Device Warehouse service": "3 ขั้นตอนง่ายๆ ในการใช้บริการ Device Warehouse",
    "1. Registered <br /> Inform the name to admin.": "1. ลงทะเบียน <br /> แจ้งชื่อให้แอดมินทราบ",
    "2. Approved <br /> within 1 day!": "2. อนุมัติ <br /> ภายใน 1 วัน!",
    "3. Book our rider <br /> to send the phone to your house !": "3.ทำสัญญาที่เพจ <br /> ทางร้านรับเครื่องไว้ใช้งาน",
    "More": "มากกว่า",
    "iphone_money_exchange_list": "<span>การแลกเปลี่ยนเงินสำหรับ iPhone</span> เป็นที่ที่ลูกค้านำโทรศัพท์มา <br />ให้ทางร้านประเมินราคาครับ เพื่อให้ลูกค้าตระหนักถึง <br /> มูลค่าที่ลูกค้าได้รับคือเงินบวกกับการโทรกลับ <br /> กลับมาใช้งานแต่เครื่องจะติด iCloud ของร้าน <br /> จนกว่าลูกค้าจะผ่อนชำระครบแล้วทางร้าน <br /> จะปลดล็อค iCloud ให้ <br /> *เครื่องของลูกค้า จะต้องไม่แนบกับ iCloud จากที่อื่น",
    "iphone_money_exchange_list_": "- ได้เงินดี - อาชีพไหนก็ซื้อขายได้ - เห็นผลไวภายใน 1 วัน ไม่ต้องมีคนค้ำ <br /> - อุปกรณ์แท้ มือ 1 การันตีจากศูนย์ไทย - มี iPhone กลับมาใช้งาน",
    "Want to spend money,  click now": "อยากใช้เงินคลิกเลย",
    "Sort by Popularity": "เรียงตามความนิยม",
    "Back": "กลับ",
    "Color": "สี",
    "Capacity": "ความจุ",
    "Quantity": "ปริมาณ",
    "Register": "ลงทะเบียน",
    "This is your reference number": "นี่คือหมายเลขอ้างอิงของคุณ",
    "you can save it.": "คุณสามารถบันทึกได้",
    "Successfully Registered": "ลงทะเบียนเรียบร้อยแล้ว",
    "Please check terms of the agreement.": "กรุณาตรวจสอบเงื่อนไขของข้อตกลง",
    "Product": "ผลิตภัณฑ์",
    "Select products": "เลือกผลิตภัณฑ์",
    "Select capacity": "เลือกความจุ",
    "Choose color": "เลือกสี",
    "Choose installment": "เลือกผ่อน",
    "Down payment amount": "จำนวนเงินดาวน์",
    "Installment": "จำนวนงวด",
    "Interested branches": "สาขาที่สนใจ",
    "Choose a branch": "เลือกสาขา",
    "ID card number": "หมายเลขบัตรประจำตัวประชาชน",
    "Fill in the ID card number": "กรอกหมายเลขบัตรประจำตัวประชาชน",
    "Phone number": "หมายเลขโทรศัพท์",
    "Enter your phone number": "ป้อนหมายเลขโทรศัพท์ของคุณ",
    "Prefix": "คำนำหน้า",
    "Choose a prefix": "เลือกคำนำหน้า",
    "Name (Thai)": "ชื่อ (ไทย)",
    "Fill in name": "กรอกชื่อ",
    "Last name": "นามสกุล",
    "Last name (Thai language)": "นามสกุล (ภาษาไทย)",
    "Current address": "ที่อยู่ปัจจุบัน",
    "House number/village/building": "บ้านเลขที่/หมู่บ้าน/อาคาร",
    "Zip code": "รหัสไปรษณีย์",
    "Enter postal code": "ป้อนรหัสไปรษณีย์",
    "Province": "จังหวัด",
    "Select province": "เลือกจังหวัด",
    "District": "เขต",
    "Choose A District": "เลือกอำเภอ",
    "Subdistrict": "ตำบล",
    "Choose A Subdistrict": "เลือกตำบล",
    "Facebook": "เฟสบุ๊ค",
    "*Incorrect entries will not be considered.": "*รายการที่ไม่ถูกต้องจะไม่ได้รับการพิจารณา",
    "Fill out facebook": "กรอกเฟสบุ๊ค",
    "ID Line": "ไอดีไลน์",
    "Enter ID Line": "ใส่ไอดีไลน์",
    "Number of people to take care of expenses": "จำนวนคนที่จะดูแลค่าใช้จ่าย",
    "Choose Number": "เลือกหมายเลข",
    "What mobile phone system do you use?": "ใช้มือถือระบบอะไรครับ?",
    "Choose a mobile phone system": "เลือกระบบโทรศัพท์มือถือ",
    "Occupation": "อาชีพ",
    "Career": "อาชีพ",
    "Choose a career": "เลือกอาชีพ",
    "Nature of Business": "ธรรมชาติของธุรกิจ",
    "Choose a business": "เลือกธุรกิจ",
    "Company name": "ชื่อ บริษัท",
    "Fill in company name": "กรอกชื่อบริษัท",
    "Place of work": "สถานที่ทำงาน",
    "Fill out the place of work.": "กรอกสถานที่ทำงาน.",
    "Job title": "ชื่องาน",
    "Fill in job title": "กรอกชื่อตำแหน่งงาน",
    "Working age": "วัยทำงาน",
    "Choose the age of work": "เลือกอายุงานได้",
    "Monthly income": "รายได้ต่อเดือน",
    "Enter your monthly income": "ป้อนรายได้ต่อเดือนของคุณ",
    "Work number": "เลขที่งาน",
    "Enter your work number": "กรอกหมายเลขที่ทำงานของคุณ",
    "Occupation": "อาชีพ",
    "Latest salary slip": "สลิปเงินเดือนล่าสุด",
    "Statement 3 months": "งบ 3 เดือน",
    "*.pdf file only": "*.pdf ไฟล์เท่านั้น",
    "Attach a file": "แนบเอกสาร",
    "University": "มหาวิทยาลัย",
    "Fill out the university": "กรอกมหาวิทยาลัย",
    "Faculty": "คณะ",
    "Fill out the faculty": "กรอกคณะ",
    "Major": "วิชาเอก",
    "Fill out field of study": "กรอกสาขาวิชาที่เรียน",
    "Education level": "ระดับการศึกษา",
    "Select level of education": "เลือกระดับการศึกษา",
    "Year": "ปี",
    "Fill in year": "กรอกปี",
    "School year attended": "ปีการศึกษาที่เข้าร่วม",
    "Enter the academic year attended.": "เข้าสู่ปีการศึกษาที่เข้าร่วม",
    "Average income": "รายได้เฉลี่ย",
    "Fill in average income": "กรอกรายได้เฉลี่ย",
    "Student card/student record": "บัตรนักศึกษา/บันทึกนักศึกษา",
    "File access code": "รหัสการเข้าถึงไฟล์",
    "Income documents": "เอกสารรายได้",
    "Do not have": "ไม่มี",
    "Relationship": "ความสัมพันธ์",
    "Choose relationship": "เลือกความสัมพันธ์",
    "Enter last name": "ใส่นามสกุล",
    "You know Device Warehouse from": "คุณรู้จัก Device Warehouse จาก",
    "Accept the terms of the agreement": "ยอมรับเงื่อนไขของข้อตกลง",
    "Privacy policy": "นโยบายความเป็นส่วนตัว",
    "Personal": "ส่วนตัว",
    "Contact": "ติดต่อ",
    "There is a cleared storefront open for service with more the 4 branches": "มีหน้าร้านเคลียร์เปิดให้บริการแล้วทั้ง 4 สาขา",
    "Affordable installment up to 12 months": "ผ่อนสบายๆ สูงสุด 12 เดือน",
    "Guaranteed Grade A iphone with 1 year warranty": "รับประกัน iphone เกรด A พร้อมรับประกัน 1 ปี",
    "Full Name": "ชื่อเต็ม",
    "Line id": "ไลน์ไอดี",
    "Monthly Installement": "ผ่อนชำระรายเดือน",
    "Cash Received Amount": "จำนวนเงินที่ได้รับเงินสด",
    "Cash Received": "เงินสดที่ได้รับ",
    "Methods of purchase": "ช่องทางการซื้อ",
    "Choose a methods of purchase": "เลือกวิธีการซื้อ",
    "First contact person": "ผู้ติดต่อรายแรก",
    "Secondary contact person": "ผู้ติดต่อรอง",
    "Booking Date": "วันที่จอง",
    "Booking Time": "เวลาจอง",
    "Downpayment": "เงินดาวน์",
    "Chiangmai": "เชียงใหม่",
    "Bangkok": "กรุงเทพฯ",
    "3. Headdown to our nearest outlet to change device for money.": "3. มุ่งหน้าไปที่<br/>ร้านที่ใกล้ที่สุดเพื่อเปลี่ยน<br/>อุปกรณ์เพื่อเงิน",
    "Maximum period": "ระยะเวลาสูงสุด",
    "Choose Maximum period": "เลือกระยะเวลาสูงสุด",
    "Home Purchase is to view and choose the choices and colour of phones.": "การซื้อบ้านคือการดูและเลือกตัวเลือกและสีของโทรศัพท์",
    "It is not compulsory to purchase the device.": "ไม่จำเป็นต้องซื้ออุปกรณ์",
    "Disclaimer: price of the amount varies with condition of of the devices and evaluation from the risk managing team": "ข้อสงวนสิทธิ์: ราคาในจำนวนจะแตกต่างกันไปตามสภาพของอุปกรณ์และการประเมินจากทีมบริหารความเสี่ยง",
    "Disclaimer: We may need a payslip for proof of employment.": "ข้อสงวนสิทธิ์: เราอาจต้องมีสลิปเงินเดือนเพื่อเป็นหลักฐานการจ้างงาน",
    "What are the core values of Device Warehouse?": "ค่านิยมหลักของ Device Warehouse คืออะไร?",
    "Customer-Centricity": "ลูกค้าเป็นศูนย์กลาง",
    "Customer-Centricity _desc_1": "ที่ Device Warehouse การให้ลูกค้าเป็นศูนย์กลางเป็นหนึ่งในค่านิยมหลักของเราที่ขับเคลื่อนธุรกิจของเรา เราเชื่อมั่นอย่างยิ่งในการให้ลูกค้าเป็นหัวใจสำคัญของทุกสิ่งที่เราทำ และสิ่งนี้มีอิทธิพลต่อการกระทำและการตัดสินใจของเราในทุกขั้นตอน",
    "Customer-Centricity _desc_2": "ด้วยการยึดเอาลูกค้าเป็นศูนย์กลางเป็นค่านิยมหลัก เรารับรองว่าความต้องการของลูกค้าอยู่ในระดับแนวหน้าของกระบวนการตัดสินใจของเรา และเราทำงานอย่างไม่เหน็ดเหนื่อยเพื่อมอบประสบการณ์ที่ยอดเยี่ยมตั้งแต่ต้นจนจบให้กับลูกค้า",
    "Reliaibility": "ความน่าเชื่อถือ",
    "Reliaibility _desc_1": "Device Warehouse ได้รับความไว้วางใจจากลูกค้าอันทรงคุณค่าหลายพันรายเนื่องจากมีหน้าร้านหลายแห่ง ซึ่งสะท้อนถึงความมุ่งมั่นของเราในการเข้าถึงและความสะดวกสบาย",
    "Reliaibility _desc_2": "เรามุ่งมั่นที่จะตอกย้ำความมุ่งมั่นของเราต่อความน่าเชื่อถือ การสร้างรายได้ และการรักษาความไว้วางใจของลูกค้าอันทรงคุณค่าของเรา",
    "efficeiency and safety": "ประสิทธิภาพและความปลอดภัย",
    "efficeiency and safety _desc_1": "ความเร็วเป็นค่านิยมหลักเป็นสิ่งสำคัญสำหรับเราที่ Device Warehouse เราเข้าใจดีว่าลูกค้าของเราให้ความสำคัญกับประสิทธิภาพและบริการที่รวดเร็ว ดังนั้นเราจึงสัญญาว่าจะให้การทำธุรกรรมที่รวดเร็ว การตอบคำถามที่รวดเร็ว และการส่งมอบผลิตภัณฑ์ที่รวดเร็ว อย่างไรก็ตาม ความมุ่งมั่นของเราในเรื่องความเร็วไม่ได้มาพร้อมกับการสูญเสียด้านความปลอดภัย",
    "efficeiency and safety _desc_2": "นอกจากนี้ เรายังให้ความสำคัญกับการรักษาความลับและความปลอดภัยของข้อมูลส่วนบุคคลของลูกค้าอีกด้วย เราได้ใช้มาตรการป้องกันและการเข้ารหัสที่มีประสิทธิภาพเพื่อปกป้องข้อมูลที่ละเอียดอ่อน เป้าหมายของเราคือเพื่อให้แน่ใจว่าในขณะที่เราจัดลำดับความสำคัญของความเร็ว เรายังจัดลำดับความสำคัญด้านความปลอดภัยและความเป็นส่วนตัวของข้อมูลลูกค้าของเราด้วย",
    "efficeiency and safety _desc_3": "ด้วยการรวมการจัดการข้อมูลส่วนบุคคลที่รวดเร็วและปลอดภัยเข้าด้วยกัน เรามุ่งมั่นที่จะมอบประสบการณ์ที่ราบรื่นและไร้กังวลให้กับลูกค้าของเรา พวกเขาสามารถวางใจได้ว่าข้อมูลส่วนบุคคลของพวกเขาได้รับการปฏิบัติด้วยความเอาใจใส่และรักษาความลับอย่างสูงสุด ในขณะเดียวกันก็เพลิดเพลินกับความสะดวกสบายและมีประสิทธิภาพจากบริการที่รวดเร็วของเรา",
    "Flexibity": "ความยืดหยุ่น",
    "Flexibity _desc_1": "ความยืดหยุ่นเป็นค่านิยมหลักพื้นฐานของ Device Warehouse เราเข้าใจถึงความสำคัญของการตอบสนองต่อความต้องการและสถานการณ์ที่หลากหลายของลูกค้าของเรา ตลอดจนการสร้างสภาพแวดล้อมเชิงบวกและครอบคลุมสำหรับพนักงานของเรา",
    "Flexibity _desc_2": "ด้วยการนำความยืดหยุ่นมาเป็นค่านิยมหลัก เราจึงมั่นใจได้ว่าพนักงานและลูกค้าของเราจะรู้สึกมีคุณค่า ได้รับการสนับสนุน และได้รับการบริการอย่างดี เรามุ่งมั่นที่จะสร้าง",
    "Device warehouse is located at:": "คลังอุปกรณ์ตั้งอยู่ที่:",
    "📍 Google Maps Bangkok Branch": "📍 Google Maps สาขากรุงเทพ",
    "Office storefront Baan Klang Muang S-Sense Soi Lat Phrao 84": "หน้าร้าน บ้านกลางเมือง s-sense ซอย ลาดพร้าว84",
    "Office storefront Kheha Romklao Branch": "หน้าร้านออฟฟิศสาขาเคหะร่มเกล้า",
    "📍 Google Maps Chiang Mai Branch": "📍 Google Maps สาขาเชียงใหม่",
    "In front of the office shop at 5 Santitham Intersection": "หน้าร้านออฟฟิศ 5 แยกสันติธรรม",
    "click > ": "คลิก >",
    "Orignal device grade A device.": "เครื่องแท้เกรดเอ.",
    "First name": "ชื่อจริง",
    "Locate Us": "ค้นหาเรา",
    "Kheha Romklao Branch, Bangkok": "Kheha Romklao Branch, Bangkok",
    "Chiang Mai Branch": "Chiang Mai Branch",
    "Our Channels": "ช่องทางของเรา",
    "Facebook Page": "เพจเฟสบุ๊ค",
    "Agent": "ตัวแทน",
    "Our Services": "บริการของเรา",
    "Get An iPhone": "รับ iPhone",
    "Trade Your iPhone For Cash": "แลกเปลี่ยน iPhone ของคุณเป็นเงินสด",
    "What We Offer At Device Warehouse": "สิ่งที่เรานําเสนอที่ Device Warehouse",
    "Get Your iPhone Now!": "รับ iPhone ของคุณตอนนี้!",
    "Low down payment, flexible installment plans": "เงินดาวน์ต่ําแผนการผ่อนชําระที่ยืดหยุ่น",
    "Trade your iPhones For Cash!": "แลกเปลี่ยน iPhone ของคุณสําหรับเงินสด!",
    "In urgent need of cash? Trade your iPhone with us!": "ต้องการเงินสดอย่างเร่งด่วนหรือไม่? แลกเปลี่ยน iPhone ของคุณกับเรา!",
    "Enjoy a hassle-free, convenient installment process when you shop at Device Warehouse": "เพลิดเพลินไปกับขั้นตอนการผ่อนชําระที่ไม่ยุ่งยากและสะดวกสบายเมื่อคุณซื้อสินค้าที่ Device Warehouse",
    "Pay In Installments": "ผ่อนชําระ",
    "Without A Credit Card": "ไม่มีบัตรเครดิต",
    "Flexible Plans": "แผนบริการแบบยืดหยุ่น",
    "No Guarantor Needed": "ไม่จําเป็นต้องมีผู้ค้ําประกัน",
    "Receive your device": "รับอุปกรณ์ของคุณ",
    "immediately after": "ทันทีหลังจาก",
    "down payment": "เงินดาวน์",
    "Installment payments are": "การผ่อนชําระคือ",
    "available for any profession": "ใช้ได้กับทุกอาชีพ",
    "Why Choose Device Warehouse?": "ทําไมต้องเลือก Device Warehouse?",
    "- High Customer Satisfaction": "- ความพึงพอใจของลูกค้าสูง",
    "- Genuine, Top-Quality Devices": "- อุปกรณ์ของแท้คุณภาพสูง",
    "- Transparent Pricing": "- การกําหนดราคาที่โปร่งใส",
    "- Affordable For All Occupations": "- ราคาไม่แพงสําหรับทุกอาชีพ",
    "Strong customer bonds are forged through genuine connections, exceptional service, and a commitment to always putting their needs first.": "สายสัมพันธ์ลูกค้าที่แข็งแกร่งถูกหล่อหลอมผ่านการเชื่อมต่อที่แท้จริงบริการที่ยอดเยี่ยมและความมุ่งมั่นที่จะให้ความสําคัญกับความต้องการของพวกเขาเป็นอันดับแรกเสมอ",
    "“At Device Warehouse, We Offer Nothing Less Than The Best”": "“ที่ Device Warehouse เรานําเสนอสิ่งที่ดีที่สุด”",
    "Visit Us Out At Our Branches To Find Out More!": "เยี่ยมชมเราได้ที่สาขาของเราเพื่อหาข้อมูลเพิ่มเติม!",
    "Flexible & Convenient Payment Methods": "วิธีการชําระเงินที่ยืดหยุ่นและสะดวกสบาย",
    "Affordable Installment Plans (Up To 12 Months)": "แผนการผ่อนชําระราคาไม่แพง (สูงสุด 12 เดือน)",
    "Genuine & Top-Quality Devices Guaranteed (Comes With A 1 Year Warranty)": "รับประกันงานซ่อม 6เดือน",
    "Get Your Device In These 3 Simple Steps!": "รับอุปกรณ์ของคุณใน 3 ขั้นตอนง่ายๆเหล่านี้!",
    "1. Fill Up Your Information On Our Website.": "1. กรอกข้อมูลของคุณบนเว็บไซต์ของเรา",
    "2. Get Approved Within A Day!": "2. ได้รับการอนุมัติภายในหนึ่งวัน!",
    "3. We Will Arrange The Delivery To Your Address!": "3. เราจะจัดส่งไปยังที่อยู่ของคุณ!",
    "Our Best Sellers Right Now!": "สินค้าขายดีของเราตอนนี้!",
    "hyperlink": "ไฮเปอร์ลิงก์",
    "Select a time": "เลือกเวลา",
    "Step 1: Add Device Warehouce LINE ID": "ขั้นตอนที่ 1: เพิ่ม Device Warehouce LINE ID",
    "Step 2: Send us your reference number to check your approval status.": "ขั้นตอนที่ 2: ส่งหมายเลขอ้างอิงของคุณเพื่อตรวจสอบสถานะการอนุมัติของคุณ",
    "Our Operation Hours Are": "เวลาทําการของเราคือ",
    "Daily": "ทุกวัน",
    "283/39,41 อาคารโฮมเพลสออฟฟิศบิลดิ้ง ชั้นที่ 8 (Mint) ซอยสุขุมวิท 55 (ทองหล่อ13) แขวงคลองตันเหนือ เขตวัฒนา กรุงเทพมหานคร 1011": "283/39,41 อาคารโฮมเพลสออฟฟิศบิลดิ้ง ชั้นที่ 8 (Mint) ซอยสุขุมวิท 55 (ทองหล่อ13) แขวงคลองตันเหนือ เขตวัฒนา กรุงเทพมหานคร 1011",
};
export default th;