import React from "react";
import {Link, NavLink } from "react-router-dom";
import strings from "./../../components/Language";

// import Logo from './../../assets/images/logo.gif';
import Logo from './../../assets/images/logo.png';
import SloganLogo from './../../assets/images/slogan_01.png';
import FloatingButtons from "./FloatingButtons";
class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: localStorage.getItem("language")
        }
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
    }

    /**
     * Change Language
     * @param {string} obj 
     */
    handleChange = async (event) => {
        let obj = event.target.value;
        if (obj === "th") {
            strings.setLanguage('th');
            await localStorage.setItem("language", 'th');
            this.setState({ lang: 'th' });
            window.location.reload();
        }
        else {
            strings.setLanguage('en');
            await localStorage.setItem("language", 'en');
            this.setState({ lang: 'en' });
            window.location.reload();
        }
    };

    render() {
        return (
            <>
                <nav className="navbar navbar-expand-lg">
                    <div className="container-fluid menu_link">
                        <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasScrolling"
                            aria-controls="offcanvasScrolling">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <NavLink className="navbar-brand nb-web mobile_logo" to={"/"}>
                            <img src={Logo} alt="" style={{ width: 115, height: 115 / 1.54 }} />
                        </NavLink>
                        <NavLink className="" to={"/"}>
                            <img src={SloganLogo} alt="" className="SloganLogo" />
                        </NavLink>
                        <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
                            <ul className="navbar-nav mb-2 mb-lg-0">
                                <li className="nav-item">
                                    <a style={{ cursor: "pointer" }} onClick={() => this.setState({ is_our_services: !this.state.is_our_services })} className="nav-link">{strings["Our Services"]}</a>
                                    {this.state.is_our_services ?
                                        <div className="dorpdown_header_menu">
                                            <NavLink to={"/installment"} className="nav-link">{strings["Get An iPhone"]}</NavLink>
                                            {/* <NavLink to={"/exchange-for-money"} className="nav-link">{strings["Trade Your iPhone For Cash"]}</NavLink> */}
                                        </div>
                                        : null}
                                </li>
                                {/* <li className="nav-item">
                                    <NavLink to={"/installment"} className="nav-link">{strings["Installment"]}</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to={"/exchange-for-money"} className="nav-link">{strings["iPhone Money Exchange"]}</NavLink>
                                </li> */}
                                <li className="nav-item">
                                    <NavLink to={"/about-us"} className="nav-link">{strings["About Us"]}</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to={"/faqs"} className="nav-link">{strings["FAQ"]}</NavLink>
                                </li>
                                {/* <li className="nav-item">
                                    <NavLink to={"/article"} className="nav-link">{strings["Article"]}</NavLink>
                                </li> */}
                                <li className="nav-item">
                                    <NavLink to={"/contact-us"} className="nav-link">{strings["Locate Us"]}</NavLink>
                                </li>
                                {/* <li className="nav-item">
                                    <NavLink to={"/work-with-us"} className="nav-link">{strings["Work With Us"]}</NavLink>
                                </li> */}
                                <li className="nav-item">
                                    <select className="lang_menu nav-link" defaultValue={this.state.lang} onChange={(e) => this.handleChange(e)}>
                                        <option selected={this.state.lang == 'th' ? true : false} value={'th'}>ไทย</option>
                                        <option selected={this.state.lang == 'en' ? true : false} value={'en'}>English&nbsp;</option>
                                    </select>
                                </li>
                            </ul>
                        </div>
                        <div className="offcanvas offcanvas-start" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1"
                            id="offcanvasScrolling" aria-labelledby="offcanvasScrollingLabel">
                            <div className="offcanvas-header pt-0">
                                <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                                <NavLink to={"/"} className="navbar-brand">
                                    <img src={Logo} alt="" style={{ width: 75, height: 75 / 1.54 }}/>
                                </NavLink>
                            </div>
                            <div className="offcanvas-body">
                                <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                                    <li className="nav-item">
                                        <a style={{ cursor: "pointer" }} onClick={() => this.setState({ is_our_services: !this.state.is_our_services })} className="nav-link">{strings["Our Services"]}</a>
                                        {this.state.is_our_services ?
                                            <div className="dorpdown_header_menu">
                                                <NavLink to={"/installment"} className="nav-link">{strings["Get An iPhone"]}</NavLink>
                                                {/* <NavLink to={"/exchange-for-money"} className="nav-link">{strings["Trade Your iPhone For Cash"]}</NavLink> */}
                                            </div>
                                            : null}
                                    </li>
                                    {/* <li className="nav-item">
                                        <NavLink to={"/installment"} className="nav-link">{strings["Installment"]}</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to={"/exchange-for-money"} className="nav-link">{strings["iPhone Money Exchange"]}</NavLink>
                                    </li> */}
                                    <li className="nav-item">
                                        <NavLink to={"/about-us"} className="nav-link">{strings["About Us"]}</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to={"/faqs"} className="nav-link">{strings["FAQ"]}</NavLink>
                                    </li>
                                    {/* <li className="nav-item">
                                        <NavLink to={"/article"} className="nav-link">{strings["Article"]}</NavLink>
                                    </li> */}
                                    <li className="nav-item">
                                        <NavLink to={"/contact-us"} className="nav-link">{strings["Locate Us"]}</NavLink>
                                    </li>
                                    {/* <li className="nav-item">
                                        <NavLink to={"/work-with-us"} className="nav-link">{strings["Work With Us"]}</NavLink>
                                    </li> */}
                                    <li className="nav-item">
                                        <select className="lang_menu nav-link" style={{ margin: "0 auto" }} defaultValue={this.state.lang} onChange={(e) => this.handleChange(e)}>
                                            <option selected={this.state.lang == 'th' ? true : false} value={'th'}>ไทย</option>
                                            <option selected={this.state.lang == 'rn' ? true : false} value={'en'}>English&nbsp;</option>
                                        </select>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav >

               
                <FloatingButtons/>
            </>
        );
    }
}

export default Header;