const en = {
    "confirm": "Confirm",
    "coming_soon": "Coming Soon",
    "processing": "Transaction submitted. Please wait",
    "error": "Error",
    "success": "Success",
    "close": "Close",
    "language": "Language",
    "language_changed": "Language Changed",
    "install_metamask": "Install Metamask",
    "please_connect": "Please Connect Wallet",
    "signin": "Sign-In",
    "signin_to_continue": "Sign In to continue",
    "register_success": "Register Success",
    "loading": "Processing...",
    "nft_collect": "Collect",
    "nft_collected": "Collected",
    "view": "View",
    "amount": "Amount",
    "date": "Date",
    "description": "Description",
    "next": "Next",
    "previous": "Previous",
    "approved": "Approved",
    "approve": "Approve",
    "unknown_net": "Unknown Net",
    "ongoing": "Ongoing",
    "expired": "Expired",
    "collected": "Collected",
    "download": "Download",
    "collect": "Collect",
    "sign_in": "Sign In",
    "wait_one_minute_for_retry": "Wait 1 Minute before retry",
    "Installment": "Installment",
    "iPhone Money Exchange": "iPhone Money Exchange",
    "About Us": "About Us",
    "FAQ": "FAQ",
    "Article": "Article",
    "Contact Us": "Contact Us",
    "Work With Us": "Work With Us",
    "Thai": "Thai",
    "English": "English",
    "Focus on safety and confidence by choosing Device Warehouse service.": "Focus on safety and confidence by choosing Device Warehouse service.",
    "There is a clear storefront.": "There is a clear storefront. ",
    "Open for service more than 10 branches": "Open for service more than 10 branches",
    "Supported by applications, convenient": "Supported by applications, convenient",
    "easy, safe": "easy, safe",
    "Installment 0%": "Installment 0%",
    "up to 12 months": "up to 12 months",
    "Genuine machine, 1st hand": "Genuine machine, 1st hand",
    "guaranteed by Thai center": "guaranteed by Thai center",
    "Home": "Home",
    "Apply now": "Apply now",
    "What are your customers interested in?": "What are your customers interested in?",
    "Which machine are you aiming for? Check it out!": "Which machine are you aiming for? Check it out!",
    "Easy installment without card": "Easy installment without card",
    "Low down payment, comfortable installments": "Low down payment, comfortable installments",
    "Click now!": "Click now!",
    "Hot money iPhone in your hand can help!": "Hot money iPhone in your hand can help!",
    "iphone money exchange": "iphone money exchange",
    "Earn good money. Have an iPhone.": "Earn good money. Have an iPhone.",
    "Best seller right now!": "Best seller right now!",
    "Prev": "Prev",
    "Next": "Next",
    "Hot": "Hot",
    "Choose a pattern": "Choose a pattern",
    "Convenience, easy installment with Device Warehouse": "Convenience, easy installment with Device Warehouse",
    "iPhone installment": "iPhone installment",
    "without credit card": "without credit card",
    "Easy installments, quick results": "Easy installments, quick results",
    "no need to have a guarantor": "no need to have a guarantor",
    "put down payment immediately": "Put down payment immediately",
    "get the device to use": "get the device to use",
    "Any occupation": "Any occupation",
    "can pay off.": "can pay off.",
    "Convenience, easy installment with Device Warehouse": "Convenience, easy installment with Device Warehouse",
    "Original equipment, 1st hand, guaranteed by Thai center": "Original equipment, 1st hand, guaranteed by Thai center",
    "Installment 0% up to 12 months": "Installment 0% up to 12 months",
    "Supported by applications, convenient, easy, safe": "Supported by applications, convenient, easy, safe",
    "There is a clear storefront Open for service more than 4 branches": "There is a clear storefront Open for service more than 4 branches",
    "Who is Device Warehouse?": "Who is Device Warehouse?",
    "Who is Device Warehouse _desc1": "At Device Warehouse, we go the extra mile to make your shopping experience convenient and hassle-free. In addition to our easy installment options and excellent customer service, we also offer the option to have your purchased phone delivered right to your doorstep.",
    "Who is Device Warehouse _desc2": "Once you have selected your desired iPhone or iPad model and completed the necessary paperwork, we will arrange for a secure and speedy delivery to your specified address. This means you can avoid the inconvenience of traveling to our store and instead have your device conveniently brought to you.",
    "Who is Device Warehouse _desc3": "We understand the importance of providing a seamless and efficient service, and our aim is to ensure that you receive your new device as quickly as possible so you can start enjoying it right away.",
    "Friends who help facilitate and give each other a good quality of life.": "Friends who help facilitate and give each other a good quality of life.",
    "That Device Warehouse stands for": "That Device Warehouse stands for",
    "companionship": "Companionship",
    "companionship_desc": "We serve everyone like a friend. A friend is someone who talks to each other in daily life. If there is anything, they can share, no matter what problems they have. Friends are always beside and helping each other.",
    "credibility": "Credibility",
    "credibility_desc": "We have a clear storefront, open for service, more than 10 branches use technology to help work together. Systematically has its own application. Support every step we intend for you to receive. maximum security",
    "Tangible": "Tangible",
    "Tangible_desc": "We want to give everyone the opportunity to be able to Access to quality products and good services at affordable prices. tangible good things suitable for all people",
    "Fast": "Fast",
    "Fast_desc": "We don't want customers to wait for a long time. It weakens our hearts, so we focus on the service that Fast, safe and must be instant too.",
    "Device Warehouse has opened more than 11 branches.": "Device Warehouse has opened more than 11 branches.",
    "aboutus_dw_desc1": "Wongwian Yai branch, near BTS Wongwian Yai,",
    "aboutus_dw_desc2": "Future Park Rangsit branch, 3rd floor (IT zone), in front of the same store as digital2home,",
    "aboutus_dw_desc3": "Bangkapi branch, in front of the Tawanna project, next to the main road (before the entrance to Makro),",
    "aboutus_dw_desc4": "Chonburi branch, Long Had Bangsaen Road (next to the shop Option Bangsaen teenagers) Khon Kaen",
    "aboutus_dw_desc5": "branch, Klang Muang Road (on the gold shop side, opposite Night Market)",
    "aboutus_dw_desc6": "Udon Thani branch, Posri Road (next to the foundation, indenting Khao Tom Shop Permphun)",
    "aboutus_dw_desc7": "Phitsanulok branch, Siharatdechochai Road (next to Bakery Town)",
    "aboutus_dw_desc8": "Branch Chiang Mai Central Chiang Mai Airport Room 316, 3rd floor (near Dtac center)",
    "aboutus_dw_desc9": "Apple house Ladprao branch Central Ladprao 3rd floor (in front of Jaymart)",
    "aboutus_dw_desc10": "Apple house Westgate branch Central Westgate 2nd floor (near IKEA entrance)",
    "aboutus_dw_desc11": "Apple House Chanthaburi branch, Central Chanthaburi, 1st floor",
    "Easy installment iphone Use a single ID card.": "Easy installment iphone Use a single ID card.",
    "Credit card can pay installments.": "Credit card can pay installments.",
    "Store address": "Store address",
    "store_address_": "99, 99 xxxx road, xxxx sub-district,<br /> xxxx district, Bangkok 10600<br /> Tel.099-9999999",
    "Contact channel": "Contact channel",
    "FanPage": "FanPage",
    "Line OA": "Line OA",
    "faq_que_1": "What occupations can pay installments?",
    "faq_ans_1_1": "The device warehouse shop offers the option for all customers, regardless of their occupation, to pay for their purchases in installments. This convenient payment method is available to students, vendors, company employees, part-time freelancers, riders, taxi drivers, housekeepers, nurses, and more, as long as they are 18 years old or older. By using their ID card, customers can easily pay for their phone in installments without the need for a credit card. However, it is necessary for customers to provide the required preliminary evidence, such as a payslip or statement, for consideration. This process allows them to enjoy the benefits of paying for their mobile phone in installments without relying on a credit card.",
    "faq_que_2": "Registration process to consider",
    "faq_ans_2_1": "1. Start by registering on the Device warehouse website. Locate and click on the `register` button to begin the process.",
    "faq_ans_2_2": "2. Choose the product you wish to purchase. In case you are interested in refinancing (exchanging your mobile phone for money), you may skip to step 3.",
    "faq_ans_2_3": "3. Fill in the additional personal information as requested. Ensure that you attach all the necessary documents.",
    "faq_ans_2_4": "4. Once you have completed filling out the information, send a message to the admin to notify them of your progress.",
    "faq_ans_2_5": "5. Wait for the admin to get back to you with the results of the consideration process and the scheduled appointment date for signing the contract.",
    "faq_que_3": "Will the store deceive and sell phones?",
    "faq_ans_3_1": "The Device warehouse shop is a company that aims to provide an alternative option for customers who wish to purchase mobile phones, iPhones, and iPads but do not have credit cards. We understand that not everyone can afford to buy these devices outright. Therefore, our shop offers a solution where customers can make a down payment and immediately receive the device. They can then pay for it through installments while using it",
    "faq_ans_3_2": "It is important for customers to carefully plan and manage their iPhone installments in a way that does not exceed their financial capabilities. We prioritize transparency and assure our customers that the Device warehouse shop will not deceive them in any way. Our goal is to provide a reliable and trustworthy option for customers to purchase their desired devices without the need for a credit card.",
    "faq_que_4": "Can I make a contract online?",
    "faq_ans_4_1": "Yes, Device warehouse offers online booking for phones to provide convenience to customers. They can easily browse the available devices on the website and choose the one that suits their preferences. Once the booking is approved, customers can choose between two options for receiving the device.",
    "faq_ans_4_2": "The first option is to visit the Device warehouse shop and personally pick up the phone. This allows customers to verify the device's condition, complete any necessary paperwork, and then take it home with them.",
    "faq_ans_4_3": "Alternatively, customers can opt to book a device rider who will deliver the phone directly to their house. This eliminates the need for customers to visit the shop, offering a convenient and hassle-free delivery service.",
    "faq_ans_4_4": "By providing these two options, Device warehouse aims to give customers flexibility based on their preferences and convenience. The company is dedicated to ensuring a smooth and satisfactory experience for all customers.",
    "faq_que_5": "What can I pay in installments?",
    "faq_ans_5_1": "At Device Warehouse, we offer customers the option to purchase iPhones and iPads through installments. Customers have the choice to either pay in cash or opt for installment plans directly with our shop. When choosing the installment option, customers can make a down payment to receive and start using the device immediately, while continuing to pay the remaining balance in installments.",
    "faq_ans_5_2": "We understand the importance of iPhones and iPads for students, so we offer special installment plans for student customers. Students can take advantage of longer installment periods, up to 12 months, to make it more manageable for them financially. Additionally, students can also share their pocket money to contribute towards the installment payments, making it convenient and hassle-free.",
    "faq_que_6": "What is mobile money exchange?",
    "faq_ans_6_1": "Mobile Money Exchange is a service provided by Device warehouse to assist customers in entering into a contract with the store for their iPhones or iPads. With this service, customers receive both money and the iPhone or iPad, allowing them to continue using the device as usual. This service differs from traditional mobile phone pawning, where the customer does not get the device back until the entire amount is paid off.",
    "faq_ans_6_2": "Device warehouse ensures that customers can keep and use their original devices throughout the contract period. This offers a more flexible and convenient option compared to traditional pawning services. By availing Mobile Money Exchange, customers receive the financial assistance they need while still having access to their iPhones or iPads.",
    "faq_ans_6_3": "Device warehouse strives to provide innovative solutions that meet customers' needs, allowing them to enjoy their devices without compromising on financial flexibility.",
    "faq_que_7": "Can I download apps with icloud?",
    "faq_ans_7_1": "Device warehouse will lock the iCloud account , customers can confidently log in to their own App Store using their personal Apple ID and download apps as usual. We respect and prioritize the customer's privacy throughout the entire process.",
    "faq_ans_7_2": "If you have any additional questions or concerns, please let us know. We're here to assist you.",
    "faq_que_8": "What is the difference between thai device and international device?",
    "faq_ans_8_1": "there are generally no significant differences between international iPhones and Thai iPhones. International iPhones typically have broader language support and compatibility with a wider range of cellular bands.",
    "faq_ans_8_2": "Furthermore, iPhone warranties are generally international, which means that the warranty coverage should be the same regardless of whether the device was purchased in Thailand or internationally.",
    "faq_que_9": "What are the business hours?",
    "faq_ans_9_1": "To avoid waiting in long queues when purchasing an iPhone in installments from our store, customers have the option to schedule an appointment with the admin via our chat service. By doing so, they can make sure they receive prompt attention and personalized assistance. Our appointments are available Monday through Sunday, from 9:00 AM to 9:00 PM.",
    "Sales Coordinate (Head Office)": "Sales Coordinate (Head Office)",
    "Job details": "Job details",
    "Job details desc": "- Taking care of customers who contact Ufriend via Line, Facebook, Tiktok <br /> - Closing sales and entering information into Ufriend's system to pass on to employees <br /> - Branch sales work answering questions and providing information about order conditions purchase goods and services  To help customers make decisions through Online and Offline channels (by phone) [Chat 80%, Call 20% approximately] <br /> - Use Excel to view Stock information and perform other related tasks. <br /> - Coordinate with other employees. To find information to answer customers correctly. <br /> - Working conditions 6 days (holidays according to the agreement of the company) emphasis on liability. like to accomplish their own work",
    "Qualifications": "Qualifications",
    "Qualifications desc": "- Female/Male, age 22 - 35 years old <br />- Qualifications: High School Vocational Certificate, High Vocational Diploma or Bachelor's degree in related fields <br />- Interested in Apple products such as iPhone/iPad <br />- Passionate about studying with mobile products Have basic knowledge about telephones <br />- Love service and take care of customers <br />- Have the skills to use Thai language correctly (Especially typing) <br />- The ability to use a computer fluently and work in front of a computer all the time, working 8 hours. <br />- Able to provide information or close sales over the phone.<br />- get along with others in coordinating as well Good interpersonal skills <br />- Able to use Microsoft Excel/Email at a basic level <br />- Able to work 6 days a week You can't stop on Saturday-Sunday.",
    "Application details": "Application details",
    "Application details desc": "Interested in applying, contact hr@devicewarehouse.co.th with the following information attached <br /> educational record <br /> work history <br />briefly introduce yourself <br />Email Subject `Apply for the position of Sales Coordinate` <br />For more information, please contact <br />Tel: 099-999-9999 (HR) from 10:00 AM to 5:30 PM.",
    "Sales (based at head office / branch)": "Sales (based at head office / branch)",
    "Sales (based at head office / branch) desc": "It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables.",
    "Debt Collection Officer (Field)": "Debt Collection Officer (Field)",
    "Debt Collection Officer (Field) desc": "It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables.",
    "Debt collection officer (by phone)": "Debt collection officer (by phone)",
    "Debt collection officer (by phone) desc": "It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables.",
    "Name": "Name",
    "Surname": "Surname",
    "Phone Number": "Phone Number",
    "Telephone Number": "Telephone Number",
    "Contact Matter": "Contact Matter",
    "Additional Text": "Additional Text",
    "Send Information": "Send Information",
    "work_with_us_thank_you_msg": "Thank you everyone who is interested in working with us. <br /> Welcome everyone to be a part of Device Warehouse.",
    "- Any occupation can pay by installment.": "- Any occupation can pay by installment.",
    "- Easy installments, quick results, no need to have a guarantor": "- Easy installments, quick results, no need to have a guarantor",
    "- Place a down payment right away get the device to use": "- Place a down payment right away get the device to use",
    "- Genuine machine, 1st hand, Thai center warranty&lt;": "- Genuine machine, 1st hand, Thai center warranty&lt;",
    "- Guaranteed grade A machine with 1 year in house warranty": "- Guaranteed grade A machine with 1 year in house warranty",
    "- iPhone, easy installment, use only one ID card": "- IPhone, easy installment, use only one ID card",
    "- Book a appointment and send the phone to your home": "- Book a appointment and send the phone to your home",
    "3 easy steps to use Device Warehouse service": "3 easy steps to use Device Warehouse service",
    "1. Registered <br /> Inform the name to admin.": "1. Registered <br /> Inform the name to admin.",
    "2. Approved <br /> within 1 day!": "2. Approved <br /> within 1 day!",
    "3. Book our rider <br /> to send the phone to your house !": "3. Book our rider <br /> to send the phone to your house !",
    "More": "More",
    "iphone_money_exchange_list": "<span>IPhone exchange for money</span> is where the customer brings the phone. <br /> Let the shop evaluate the price. to make customers aware of <br /> The value that the customer receives is money plus a phone call back. <br /> Return to use, but the device will be attached to the shop's iCloud <br /> until the customer has paid all installments, then the shop <br /> will unlock the iCloud for <br /> *The customer's device must not be attached to iCloud from other places.",
    "iphone_money_exchange_list_": "- Get good money - Any occupation can be traded - Get results quickly within 1 day, no guarantor needed <br /> - Original equipment, 1st hand, guaranteed by Thai center - Have an iPhone back to use",
    "Want to spend money,  click now": "Want to spend money,  click now",
    "Sort by Popularity": "Sort by Popularity",
    "Back": "Back",
    "Color": "Color",
    "Capacity": "Capacity",
    "Quantity": "Quantity",
    "Register": "Register",
    "This is your reference number": "This is your reference number",
    "you can save it.": "you can save it.",
    "Successfully Registered": "Successfully Registered",
    "Please check terms of the agreement.": "Please check terms of the agreement.",
    "Product": "Product",
    "Select products": "Select products",
    "Select capacity": "Select capacity",
    "Choose color": "Choose color",
    "Choose installment": "Choose installment",
    "Down payment amount": "Down payment amount",
    "Installment": "Installment",
    "Interested branches": "Interested branches",
    "Choose a branch": "Choose a branch",
    "ID card number": "ID card number",
    "Fill in the ID card number": "Fill in the ID card number",
    "Phone number": "Phone number",
    "Enter your phone number": "Enter your phone number",
    "Prefix": "Prefix",
    "Choose a prefix": "Choose a prefix",
    "Name (Thai)": "Name (Thai)",
    "Fill in name": "Fill in name",
    "Last name": "Last name",
    "Last name (Thai language)": "Last name (Thai language)",
    "Current address": "Current address",
    "House number/village/building": "House number/village/building",
    "Zip code": "Zip code",
    "Enter postal code": "Enter postal code",
    "Province": "Province",
    "Select province": "Select province",
    "District": "District",
    "Choose A District": "Choose A District",
    "Subdistrict": "Subdistrict",
    "Choose A Subdistrict": "Choose A Subdistrict",
    "Facebook": "Facebook",
    "*Incorrect entries will not be considered.": "*Incorrect entries will not be considered.",
    "Fill out facebook": "Fill out facebook",
    "ID Line": "ID Line",
    "Enter ID Line": "Enter ID Line",
    "Number of people to take care of expenses": "Number of people to take care of expenses",
    "Choose Number": "Choose Number",
    "What mobile phone system do you use?": "What mobile phone system do you use?",
    "Choose a mobile phone system": "Choose a mobile phone system",
    "Occupation": "Occupation",
    "Career": "Career",
    "Choose a career": "Choose a career",
    "Nature of Business": "Nature of Business",
    "Choose a business": "Choose a business",
    "Company name": "Company name",
    "Fill in company name": "Fill in company name",
    "Place of work": "Place of work",
    "Fill out the place of work.": "Fill out the place of work.",
    "Job title": "Job title",
    "Fill in job title": "Fill in job title",
    "Working age": "Working age",
    "Choose the age of work": "Choose the age of work",
    "Monthly income": "Monthly income",
    "Enter your monthly income": "Enter your monthly income",
    "Work number": "Work number",
    "Enter your work number": "Enter your work number",
    "Occupation": "Occupation",
    "Latest salary slip": "Latest salary slip",
    "Statement 3 months": "Statement 3 months",
    "*.pdf file only": "*.pdf file only",
    "Attach a file": "Attach a file",
    "University": "University",
    "Fill out the university": "Fill out the university",
    "Faculty": "Faculty",
    "Fill out the faculty": "Fill out the faculty",
    "Major": "Major",
    "Fill out field of study": "Fill out field of study",
    "Education level": "Education level",
    "Select level of education": "Select level of education",
    "Year": "Year",
    "Fill in year": "Fill in year",
    "School year attended": "School year attended",
    "Enter the academic year attended.": "Enter the academic year attended.",
    "Average income": "Average income",
    "Fill in average income": "Fill in average income",
    "Student card/student record": "Student card/student record",
    "File access code": "File access code",
    "Income documents": "Income documents",
    "Do not have": "Do not have",
    "Relationship": "Relationship",
    "Choose relationship": "Choose relationship",
    "Enter last name": "Enter last name",
    "You know Device Warehouse from": "You know Device Warehouse from",
    "Accept the terms of the agreement": "Accept the terms of the agreement",
    "Privacy policy": "Privacy policy",
    "Personal": "Personal",
    "Contact": "Contact",
    "There is a cleared storefront open for service with more the 4 branches": "There is a cleared storefront open for service with more the 4 branches",
    "Affordable installment up to 12 months": "Affordable installment up to 12 months",
    "Guaranteed Grade A iphone with 1 year warranty": "Guaranteed Grade A iphone with 1 year warranty",
    "Full Name": "Full Name",
    "Line id": "Line id",
    "Monthly Installement": "Monthly Installement",
    "Cash Received Amount": "Cash Received Amount",
    "Cash Received": "Cash Received",
    "Methods of purchase": "Methods of purchase",
    "Choose a methods of purchase": "Choose a methods of purchase",
    "First contact person": "First contact person",
    "Secondary contact person": "Secondary contact person",
    "Booking Date": "Booking Date",
    "Booking Time": "Booking Time",
    "Downpayment": "Downpayment",
    "Chiangmai": "Chiangmai",
    "Bangkok": "Bangkok",
    "3. Headdown to our nearest outlet to change device for money.": "3. Headdown to our <br/> nearest outlet to change<br/> device for money.",
    "Maximum period": "Maximum period",
    "Choose Maximum period": "Choose Maximum period",
    "Home Purchase is to view and choose the choices and colour of phones.": "Home Purchase is to view and choose the choices and colour of phones.",
    "It is not compulsory to purchase the device.": "It is not compulsory to purchase the device.",
    "Disclaimer: price of the amount varies with condition of of the devices and evaluation from the risk managing team": "Disclaimer: price of the amount varies with condition of of the devices and evaluation from the risk managing team",
    "Disclaimer: We may need a payslip for proof of employment.": "Disclaimer: We may need a payslip for proof of employment.",
    "What are the core values of Device Warehouse?": "What are the core values of Device Warehouse?",
    "Customer-Centricity": "Customer-Centricity",
    "Customer-Centricity _desc_1": "At Device Warehouse, customer-centricity is one of our core values that drives our business. We firmly believe in putting our customers at the heart of everything we do, and it influences our actions and decisions every step of the way.",
    "Customer-Centricity _desc_2": "By embracing customer-centricity as a core value, we ensure that our customers' needs are at the forefront of our decision-making process, and we work tirelessly to provide them with an exceptional experience from beginning to end.",
    "Reliaibility": "Reliaibility",
    "Reliaibility _desc_1": "Device Warehouse is trusted by thousands of valuable customers due to its multiple shop fronts, reflecting our commitment to accessibility and convenience. ",
    "Reliaibility _desc_2": "we aim to underscore our commitment to reliability, earning and maintaining the trust of our valued customers.",
    "efficeiency and safety": "efficeiency and safety",
    "efficeiency and safety _desc_1": "Speed as a core value is crucial to us at Device Warehouse. We understand that our customers value efficiency and quick service. Hence, we promise to provide speedy transactions, swift responses to inquiries, and prompt delivery of products. However, our commitment to speed does not come at the expense of security.",
    "efficeiency and safety _desc_2": "In addition, we prioritize the confidentiality and security of our customers' personal data. We have implemented robust safeguards and encryption measures to protect sensitive information. Our aim is to ensure that while we prioritize speed, we also prioritize the security and privacy of our customers' data.",
    "efficeiency and safety _desc_3": "By combining speed and secure personal data management, we strive to offer a seamless and worry-free experience for our customers. They can trust that their personal information is treated with the utmost care and confidentiality while enjoying the convenience and efficiency that our speedy services provide.",
    "Flexibity": "Flexibity",
    "Flexibity _desc_1": "Flexibility is a fundamental core value at Device Warehouse. We understand the importance of catering to the diverse needs and circumstances of our customers, as well as creating a positive and inclusive environment for our employees.",
    "Flexibity _desc_2": "By embracing flexibility as a core value, we ensure that our employees and customers feel valued, supported, and well-served. We strive to create",
    "Device warehouse is located at:": "Device warehouse is located at:",
    "📍 Google Maps Bangkok Branch": "📍 Google Maps Bangkok Branch",
    "Office storefront Baan Klang Muang S-Sense Soi Lat Phrao 84": "office storefront Baan Klang Muang S-Sense Soi Lat Phrao 84",
    "Office storefront Kheha Romklao Branch": "office storefront Kheha Romklao Branch",
    "📍 Google Maps Chiang Mai Branch": "📍 Google Maps Chiang Mai Branch",
    "In front of the office shop at 5 Santitham Intersection": "In front of the office shop at 5 Santitham Intersection",
    "click > ": "click > ",
    "Orignal device grade A device.": "Orignal device grade A device.",
    "First name": "First name",
    "Locate Us": "Locate Us",
    "Kheha Romklao Branch, Bangkok": "Kheha Romklao Branch, Bangkok",
    "Chiang Mai Branch": "Chiang Mai Branch",
    "Our Channels": "Our Channels",
    "Facebook Page": "Facebook Page",
    "Agent": "Agent",
    "Our Services": "Our Services",
    "Get An iPhone": "Get An iPhone",
    "Trade Your iPhone For Cash": "Trade Your iPhone For Cash",
    "What We Offer At Device Warehouse": "What We Offer At Device Warehouse",
    "Get Your iPhone Now!": "Get Your iPhone Now!",
    "Low down payment, flexible installment plans": "Low down payment, flexible installment plans",
    "Trade your iPhones For Cash!": "Trade your iPhones For Cash!",
    "In urgent need of cash? Trade your iPhone with us!": "In urgent need of cash? Trade your iPhone with us!",
    "Enjoy a hassle-free, convenient installment process when you shop at Device Warehouse": "Enjoy a hassle-free, convenient installment process when you shop at Device Warehouse",
    "Pay In Installments": "Pay In Installments",
    "Without A Credit Card": "Without A Credit Card",
    "Flexible Plans": "Flexible Plans",
    "No Guarantor Needed": "No Guarantor Needed",
    "Receive your device": "Receive your device",
    "immediately after": "immediately after",
    "down payment": "down payment",
    "Installment payments are": "Installment payments are",
    "available for any profession": "available for any profession",
    "Why Choose Device Warehouse?": "Why Choose Device Warehouse?",
    "- High Customer Satisfaction": "- High Customer Satisfaction",
    "- Genuine, Top-Quality Devices": "- Genuine, Top-Quality Devices",
    "- Transparent Pricing": "- Transparent Pricing",
    "- Affordable For All Occupations": "- Affordable For All Occupations",
    "Strong customer bonds are forged through genuine connections, exceptional service, and a commitment to always putting their needs first.": "Strong customer bonds are forged through genuine connections, exceptional service, and a commitment to always putting their needs first.",
    "“At Device Warehouse, We Offer Nothing Less Than The Best”": "“At Device Warehouse, We Offer Nothing Less Than The Best”",
    "Visit Us Out At Our Branches To Find Out More!": "Visit Us Out At Our Branches To Find Out More!",
    "Flexible & Convenient Payment Methods": "Flexible & Convenient Payment Methods",
    "Affordable Installment Plans (Up To 12 Months)": "Affordable Installment Plans (Up To 12 Months)",
    "Genuine & Top-Quality Devices Guaranteed (Comes With A 1 Year Warranty)": "Genuine & Top-Quality Devices Guaranteed (Comes With A 1 Year Warranty)",
    "Get Your Device In These 3 Simple Steps!": "Get Your Device In These 3 Simple Steps!",
    "1. Fill Up Your Information On Our Website.": "1. Fill Up Your Information On Our Website.",
    "2. Get Approved Within A Day!": "2. Get Approved Within A Day!",
    "3. We Will Arrange The Delivery To Your Address!": "3. We Will Arrange The Delivery To Your Address!",
    "Our Best Sellers Right Now!": "Our Best Sellers Right Now!",
    "hyperlink": "hyperlink",
    "Select a time": "Select a time",
    "Step 1: Add Device Warehouce LINE ID": "Step 1: Add Device Warehouce LINE ID",
    "Step 2: Send us your reference number to check your approval status.": "Step 2: Send us your reference number to check your approval status.",
    "Our Operation Hours Are": "Our Operation Hours Are",
    "Daily": "Daily",
    "283/39,41 อาคารโฮมเพลสออฟฟิศบิลดิ้ง ชั้นที่ 8 (Mint) ซอยสุขุมวิท 55 (ทองหล่อ13) แขวงคลองตันเหนือ เขตวัฒนา กรุงเทพมหานคร 1011": "283/39,41 อาคารโฮมเพลสออฟฟิศบิลดิ้ง ชั้นที่ 8 (Mint) ซอยสุขุมวิท 55 (ทองหล่อ13) แขวงคลองตันเหนือ เขตวัฒนา กรุงเทพมหานคร 1011",
};
export default en;