const BaseURL = process.env.REACT_APP_API_URL;
const GuestURL = BaseURL + '/api/';
const UserURL = BaseURL + '/api/user/';

export const api = {
    SiteURL: BaseURL,

    /** Guest API */
    app_version: GuestURL + "app/version/android",

    best_seller: GuestURL + "best-seller",
    product: GuestURL + "product",
    product_register_step_1: GuestURL + "product-register",
    product_register_step_1_validate: GuestURL + "product-register/validate",
    product_register_step_2: GuestURL + "product-register/step-2",
    product_register_step_2_validate: GuestURL + "product-register/step-2/validate",
    product_register_available_time_slot: GuestURL + "product-register/get-available-time-slot",
    product_register_step_3: GuestURL + "product-register/step-3",
    product_register_step_3_validate: GuestURL + "product-register/step-3/validate",
    product_register_step_4: GuestURL + "product-register/step-4",
    product_register_step_4_validate: GuestURL + "product-register/step-4/validate",
    product_register_step_5: GuestURL + "product-register/step-5",
    product_register_step_5_validate: GuestURL + "product-register/step-5/validate",
    product_register_submit: GuestURL + "product-register/submit",
    contact_us: GuestURL + "contact-us",
}