import React from 'react';
import { Link } from 'react-router-dom'

import Header from "../../components/Layout/Header";
import Footer from "../../components/Layout/Footer";
import WarehouseService from '../../components/Layout/WarehouseService';
import { Helmet } from 'react-helmet';
import strings from '../../components/Language';

class ArticleScreen extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		}
	}

	async componentDidMount() {
		window.scrollTo(0, 0);
	}

	render() {
		return (
			<>
				<Helmet><title>{"Device Warehouse | " + strings["Article"]}</title></Helmet>
				<Header />

				<div className="container">
					<div className="blog">
						<div className="row">
							<div className="col-12 mb-3">
								<h2>{strings["Article"]}</h2>
							</div>
							<div className="col mb-3">
								<div className="mb-3 position-relative">
									<img src="assets/images/ist.jpg" className="d-block w-100" alt="" />
									<div className="overlay">
										<h2>{strings["Easy installment without card"]}</h2>
										<p>{strings["Low down payment, comfortable installments"]}</p>
									</div>
								</div>
								<h3>
									{strings["Easy installment iphone Use a single ID card."]} <br />
									{strings["Credit card can pay installments."]}
								</h3>
							</div>
							<div className="col mb-3">
								<div className="mb-3 position-relative">
									<img src="assets/images/ist.jpg" className="d-block w-100" alt="" />
									<div className="overlay">
										<h2>{strings["Easy installment without card"]}</h2>
										<p>{strings["Low down payment, comfortable installments"]}</p>
									</div>
								</div>
								<h3>
									{strings["Easy installment iphone Use a single ID card."]} <br />
									{strings["Credit card can pay installments."]}
								</h3>
							</div>
							<div className="col mb-3">
								<div className="mb-3 position-relative">
									<img src="assets/images/ist.jpg" className="d-block w-100" alt="" />
									<div className="overlay">
										<h2>{strings["Easy installment without card"]}</h2>
										<p>{strings["Low down payment, comfortable installments"]}</p>
									</div>
								</div>
								<h3>
									{strings["Easy installment iphone Use a single ID card."]} <br />
									{strings["Credit card can pay installments."]}
								</h3>
							</div>
						</div>
					</div>
				</div>

				<WarehouseService />
				<Footer />
			</>
		);
	}
}

export default ArticleScreen;
